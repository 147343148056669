import { PageContainer, PageHeader } from '@marlin/shared/ui-page';
import SimCardDownloadRoundedIcon from '@mui/icons-material/SimCardDownloadRounded';

import { content } from './content';
import { ExportTelemetryForm } from './export-telemetry.form';

export const ExportTelemetry = () => {
  return (
    <PageContainer>
      <PageHeader
        icon={<SimCardDownloadRoundedIcon />}
        title={content.TITLE}
        prefix="export-telemetry-hub-header"
        subtitle={content.SUBTITLE}
      />
      <ExportTelemetryForm />
    </PageContainer>
  );
};
