export const content = {
  POSTAL_CODE: 'Postal Code',
  BUTTON_CANCEL: 'Cancel',
  LOCATION_ADDRESS: 'Location Address',
  COUNTRY_LABEL: 'Country',
  ADDRESS_1_LABEL: 'Address 1',
  ADDRESS_2_LABEL: 'Address 2',
  CITY_LABEL: 'City',
  STATE_LABEL: 'State',
  BUTTON_UPDATE: 'Update',
  BUTTON_CREATE: 'Create',
  REQUIRED: 'This is a required field',
  MIN_MAX: 'Must be between 3 and 200 characters',
  MAX_LENGTH: (max: number) => `Value cannot exceed ${max} characters`,
  EDIT: 'Edit',
  ORGANIZATION_TIME_ZONE: 'Organization Time Zone',
};
