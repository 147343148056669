import { MarlinTheme } from '@marlin/shared/theme';
import { SkeletonLoader } from '@marlin/shared/ui-loader';
import { AnnotationTooltip } from '@marlin/shared/ui/annotation-tooltip';
import { IAnnotation } from '@marlin/shared/ui/annotation-tooltip';
import { EmptyChartData } from '@marlin/shared/ui/chart';
import { IChartSeries, TChartDisplayType, getTimeLineChartData } from '@marlin/shared/utils-chart';
import { CircularProgress, alpha } from '@mui/material';
import ApexChart from 'react-apexcharts';
import { makeStyles } from 'tss-react/mui';

const chartDesktopHeight = 310;
const singleRangeBarChartDesktopHeight = 38;
const rangeBarDesktopHeightFactor = 94;
const chartContainerTopMargin = 32;
const apexChartMargin = 16;

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    marginTop: theme.typography.pxToRem(32),
    position: 'relative',
    '& .apexcharts-zoomin-icon:has(.disabled), .apexcharts-zoom-icon:has(.disabled)': {
      pointerEvents: 'none',
      cursor: 'not-allowed',
      opacity: '0.5',
    },
    '& .apexcharts-svg.apexcharts-zoomable.hovering-pan': {
      cursor: 'grab',
    },
  },
  // RangeBar and Bar chart do not change cursor https://github.com/apexcharts/apexcharts.js/issues/2244
  barsChart: {
    '& .apexcharts-svg.apexcharts-zoomable': {
      cursor: 'crosshair',
    },
    height: '100%',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: alpha(theme.palette.background.alternative, 0.7),
    zIndex: theme.zIndex.drawer,
  },
  skeleton: {
    height: '100%',
    width: '100%',
  },
}));

interface IChartWrapperProps {
  isLoading: boolean;
  isFetching: boolean;
  chartId: string;
  chartData: IChartSeries[];
  currentAnnotationTooltip: IAnnotation | null;
  to: number;
  from: number;
  chartOptions: ApexCharts.ApexOptions;
  chartDisplayType: TChartDisplayType;
  isFullScreen?: boolean;
  headerHeight?: number;
  rangeBarDatapoints?: string[];
  paperHeight?: number;
  className?: string;
  height?: number;
}

export const ChartSwitcherWrapper = ({
  isLoading,
  isFetching,
  chartId,
  chartData,
  currentAnnotationTooltip,
  to,
  from,
  chartDisplayType,
  className,
  ...props
}: IChartWrapperProps) => {
  const { classes, cx } = useStyles();

  return (
    <SkeletonLoader loading={isLoading} skeletonsCount={5}>
      <div
        className={cx(classes.container, className, {
          [classes.barsChart]: chartDisplayType === 'bar' || chartDisplayType === 'rangeBar',
        })}
        data-testid={chartId}
      >
        {isFetching && !isLoading && (
          <div className={classes.overlay}>
            <CircularProgress />
          </div>
        )}
        <ChartSwitcher chartData={chartData} to={to} chartDisplayType={chartDisplayType} {...props} />
        {currentAnnotationTooltip && <AnnotationTooltip {...currentAnnotationTooltip} />}
        {!isLoading && <EmptyChartData chartData={chartData} from={from} to={to} />}
      </div>
    </SkeletonLoader>
  );
};

export const ChartSwitcher = ({
  chartData,
  chartOptions,
  chartDisplayType,
  isFullScreen,
  height,
  to,
  headerHeight = 0,
  rangeBarDatapoints,
  paperHeight = 0,
}: Omit<IChartWrapperProps, 'chartId' | 'isLoading' | 'isFetching' | 'currentAnnotationTooltip' | 'from'>) => {
  if (chartDisplayType === 'rangeBar') {
    const rangeBarDataSeries = rangeBarDatapoints
      ? getTimeLineChartData({
          chartData,
          to,
          possibleDatapoints: rangeBarDatapoints,
        })
      : [];

    const getHeight = () => {
      if (height) {
        return height;
      }

      if (chartData.length && rangeBarDatapoints) {
        return rangeBarDatapoints.length * singleRangeBarChartDesktopHeight + rangeBarDesktopHeightFactor;
      }

      return chartDesktopHeight;
    };

    return (
      <ApexChart
        width="100%"
        height={getHeight()}
        series={rangeBarDataSeries}
        options={chartOptions}
        type="rangeBar"
        key={chartDisplayType}
      />
    );
  }

  const fullChartHeight = paperHeight - headerHeight - chartContainerTopMargin - apexChartMargin;

  return (
    <ApexChart
      width="100%"
      height={isFullScreen ? fullChartHeight : chartDesktopHeight}
      series={chartData}
      options={chartOptions}
      type={chartDisplayType}
      key={chartDisplayType}
    />
  );
};
