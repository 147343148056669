import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    '& .MuiList-root': {
      paddingTop: 0,
      paddingBottom: 0,
      minWidth: theme.typography.pxToRem(384),
      maxHeight: theme.typography.pxToRem(400),
    },
    zIndex: 1303, // it should be lower than material Dialog component zIndex: 1300
  },

  searchInput: {
    width: '100%',
    '& .Mui-focused .MuiInputAdornment-root svg': {
      color: theme.palette.primary.main,
    },
    '& .MuiInput-root': {
      padding: theme.typography.pxToRem(10),
    },
    '& .Mui-focused': {
      backgroundColor: '#0029B90A', //TODO ADD COLORS TO PALLETE
    },
  },
}));
