import { zodResolver } from '@hookform/resolvers/zod';
import { useExportTelemetry } from '@marlin/asset/data-access/device';
import { createDateString, dateAdapter } from '@marlin/shared/utils-common-date';
import { IRequestError } from '@marlin/shared/utils/react-query';
import { Button, CircularProgress, Paper, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { content } from './content';
import { ExportTelemetryErrorMessage } from './export-telemetry-error.component';
import { TExportTelemetryForm, exportTelemetrySchema } from './export-telemetry.form.schema';
import { useStyles } from './export-telemetry.form.styles';

enum EXPORT_TELEMETRY_ERROR_CODE {
  NO_DEVICES_FOUND = 101,
}

const getErrorMessage = (errorCode?: EXPORT_TELEMETRY_ERROR_CODE) => {
  switch (errorCode) {
    case EXPORT_TELEMETRY_ERROR_CODE.NO_DEVICES_FOUND:
      return content.NO_SENSORS_FOUND;
    default:
      return content.ERROR_MESSAGE;
  }
};

export const ExportTelemetryForm = () => {
  const { classes } = useStyles();
  const form = useForm<TExportTelemetryForm>({
    defaultValues: {
      fromDate: createDateString(dateAdapter.date()?.subtract(7, 'days').toDate()),
      toDate: createDateString(dateAdapter.date()?.toDate()),
      dateRange: '',
    },
    mode: 'onSubmit',
    resolver: zodResolver(exportTelemetrySchema),
  });

  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync, isLoading } = useExportTelemetry();

  const onSubmit: SubmitHandler<Omit<TExportTelemetryForm, 'dateRange'>> = (data) => {
    return mutateAsync({
      fromDate: data.fromDate ?? '',
      toDate: data.toDate ?? '',
      deviceIds: [],
    })
      .then(({ url }) => {
        window.open(url, '_blank');
        enqueueSnackbar(content.FILE_EXPORTED, {
          variant: 'success',
          preventDuplicate: true,
        });
      })
      .catch((error: AxiosError<IRequestError<EXPORT_TELEMETRY_ERROR_CODE>>) => {
        enqueueSnackbar(getErrorMessage(error?.response?.data?.errorCode), {
          variant: 'error',
          preventDuplicate: true,
        });
      });
  };

  const showSnackbarWhenReversDate = () => {
    enqueueSnackbar(content.REVERSED_DATE, {
      variant: 'info',
      preventDuplicate: true,
    });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Paper className={classes.formContainer}>
          <Typography className={classes.title}>{content.FORM_TITLE}</Typography>
          <Typography className={classes.subtitle}>{content.FORM_SUBTITLE}</Typography>
          <div className={classes.pickersContainer}>
            <Controller<TExportTelemetryForm>
              name="fromDate"
              render={({ field, fieldState, formState }) => {
                return (
                  <DatePicker
                    {...field}
                    views={['year', 'month', 'day']}
                    className={classes.picker}
                    label={content.FROM}
                    value={field.value ? dateAdapter.date(field.value)?.toDate() : null}
                    onChange={(date) => {
                      const toDate = dateAdapter.date(form.getValues('toDate'))?.toDate();
                      const fromDate = dateAdapter.isValid(date) ? createDateString(date || undefined) : null;
                      if (fromDate && toDate && toDate < new Date(fromDate)) {
                        field.onChange(createDateString(toDate));
                        form.setValue('toDate', fromDate);
                        showSnackbarWhenReversDate();
                      } else {
                        field.onChange(fromDate);
                      }
                      form.trigger(['dateRange']);
                    }}
                    slotProps={{
                      textField: {
                        error: !!fieldState.error || !!formState.errors.dateRange,
                        helperText: fieldState.error?.message,
                        required: true,
                      },
                    }}
                  />
                );
              }}
            />
            <Controller<TExportTelemetryForm>
              name="toDate"
              render={({ field, fieldState, formState }) => {
                return (
                  <DatePicker
                    {...field}
                    views={['year', 'month', 'day']}
                    className={classes.picker}
                    label={content.TO}
                    value={field.value ? dateAdapter.date(field.value)?.toDate() : null}
                    onChange={(date) => {
                      const fromDate = dateAdapter.date(form.getValues('fromDate'))?.toDate();
                      const toDate = dateAdapter.isValid(date) ? createDateString(date || undefined) : null;
                      if (fromDate && toDate) {
                        const jsToDate = new Date(toDate);
                        if (jsToDate.getFullYear() >= 2022 && jsToDate < fromDate) {
                          field.onChange(createDateString(fromDate));
                          form.setValue('fromDate', toDate);
                          showSnackbarWhenReversDate();
                        } else {
                          field.onChange(toDate);
                        }
                      } else {
                        field.onChange(toDate);
                      }
                      form.trigger(['dateRange']);
                    }}
                    slotProps={{
                      textField: {
                        error: !!fieldState.error || !!formState.errors.dateRange,
                        helperText: fieldState.error?.message,
                        required: true,
                      },
                    }}
                  />
                );
              }}
            />
          </div>
          <ExportTelemetryErrorMessage message={form.formState.errors?.dateRange?.message} />
          <div>
            <Button
              variant="contained"
              type="submit"
              data-testid="export-to-csv-btn"
              disabled={!form.formState.isValid}
            >
              {isLoading ? content.FILE_EXPORTING : content.EXPORT_BTN}
              {isLoading && <CircularProgress size={25} className={classes.progress} />}
            </Button>
          </div>
        </Paper>
      </form>
    </FormProvider>
  );
};
