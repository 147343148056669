import { z } from 'zod';

export const exportTelemetryPayload = z.object({
  fromDate: z.string(),
  toDate: z.string(),
  deviceIds: z.array(z.string()),
});

export const exportTelemetryResponseSchema = z.object({
  url: z.string(),
});

export type TExportTelemetryPayload = z.infer<typeof exportTelemetryPayload>;
export type TExportTelemetryResponseSchema = z.infer<typeof exportTelemetryResponseSchema>;
