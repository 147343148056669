import { MarlinTheme } from '@marlin/shared/theme';
import { StepperModal } from '@marlin/shared/ui-modal';
import { warrantyStorageService } from '@marlin/shared/utils-common-warranty-storage';
import { getLogger } from '@marlin/shared/utils/logger';
import { Divider, FormHelperText } from '@mui/material';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content as loggerContent } from '../../../content';
import { EQUIPMENT_ENUM, TEquipment } from '../../onboarding.schema';
import { useOnboardingState } from '../../use-onboarding-state.hook';
import { content } from './content';
import { EquipmentCard } from './equipment-card.component';
import { EquipmentTiles } from './equipment-tiles.component';
import { AercoInnovation } from './forms/aerco-innovation.component';
import { aercoDescriptionSteps, intellistationDescriptionSteps } from './forms/common-parts';
import { EquipmentLimit } from './forms/equipment-limit.component';
import { NoForm } from './forms/no-form.component';
import { PowersIntellistation } from './forms/powers-intellistation.component';
import { checkIfDuplicatedNames, checkIfDuplicatedSerialNumbers } from './utils';

const equipmentLimit = 3;

export interface IEquipmentFormProps {
  equipmentType: string | null;
  equipment: TEquipment | null;
  isEditForm: boolean;
  onCancel?: () => void;
  index: number;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  divider: {
    marginTop: theme.typography.pxToRem(32),
    marginBottom: theme.typography.pxToRem(32),
  },
}));

export const EquipmentSwitcher = () => {
  const { classes } = useStyles();
  const [selectedEquipment, setSelectedEquipment] = useState<EQUIPMENT_ENUM | null>(null);
  const [selectedEditEquipment, setSelectedEditEquipment] = useState<EQUIPMENT_ENUM | null>(null);
  const [disableFurtherRegistration, setDisableFurtherRegistration] = useState(false);
  const [isInstructionsModalOpen, setIsInstructionsModalOpen] = useState<{ isOpen: boolean; defaultStep?: number }>({
    isOpen: false,
  });
  const { onboardingState } = useOnboardingState();

  const warrantyModel = warrantyStorageService.get();

  if (warrantyModel === 'intellistation2' && selectedEquipment !== EQUIPMENT_ENUM.INTELLISTATION) {
    setSelectedEquipment(EQUIPMENT_ENUM.INTELLISTATION);
  }

  if (
    warrantyModel === 'innovationedge' &&
    selectedEquipment !== EQUIPMENT_ENUM.AERCO_INNOVATION &&
    !onboardingState.equipment.length
  ) {
    setSelectedEquipment(EQUIPMENT_ENUM.AERCO_INNOVATION);
  }

  const openInstructionsModal = useCallback((defaultStep?: number) => {
    setIsInstructionsModalOpen({ isOpen: true, defaultStep });
  }, []);

  const closeInstructionsModal = useCallback(() => {
    setIsInstructionsModalOpen({ isOpen: false });
  }, []);

  const onEquipmentCardDeleteClick = useCallback(() => {
    getLogger()?.track(loggerContent.LOGGER.SELF_SERVICE.REMOVE_EQUIPMENT);
    if (disableFurtherRegistration) {
      setDisableFurtherRegistration(false);
    }
  }, [disableFurtherRegistration]);

  const resetSwitcher = useCallback(() => {
    setSelectedEquipment(null);

    if (warrantyModel && warrantyModel !== 'innovationedge') {
      setDisableFurtherRegistration(true);
    }
  }, [warrantyModel]);

  const equipmentForm = (data: IEquipmentFormProps) => {
    const { equipmentType, equipment, isEditForm, onCancel, index } = data;
    switch (equipmentType) {
      case EQUIPMENT_ENUM.INTELLISTATION: {
        return (
          <PowersIntellistation
            resetSwitcher={resetSwitcher}
            isEditForm={isEditForm}
            onCancel={onCancel}
            equipment={equipment}
            openInstructionsModal={openInstructionsModal}
            index={index}
          />
        );
      }
      case EQUIPMENT_ENUM.AERCO_INNOVATION: {
        return (
          <AercoInnovation
            resetSwitcher={resetSwitcher}
            isEditForm={isEditForm}
            onCancel={onCancel}
            equipment={equipment}
            openInstructionsModal={openInstructionsModal}
            index={index}
          />
        );
      }
      // HIDE BENCHMARK FORM
      // case EQUIPMENT_ENUM.AERCO_BENCHMARK: {
      //   return (
      //     <AercoBenchmark
      //       resetSwitcher={resetSwitcher}
      //       isEditForm={isEditForm}
      //       onCancel={onCancel}
      //       equipment={equipment}
      //       openInstructionsModal={openInstructionsModal}
      //       index={index}
      //     />
      //   );
      // }
      default: {
        return <NoForm equipmentLength={onboardingState.equipment.length} />;
      }
    }
  };

  const instructionsModalContent = useMemo(() => {
    switch (selectedEditEquipment ? selectedEditEquipment : selectedEquipment) {
      case EQUIPMENT_ENUM.INTELLISTATION: {
        return {
          withStepCount: true,
          steps: intellistationDescriptionSteps,
        };
      }
      case EQUIPMENT_ENUM.AERCO_INNOVATION: {
        return {
          withStepCount: false,
          steps: aercoDescriptionSteps,
        };
      }
      // case EQUIPMENT_ENUM.AERCO_BENCHMARK: {
      //   return {
      //     withStepCount: false,
      //     steps: aercoDescriptionSteps,
      //   };
      // }
      default: {
        return {
          withStepCount: undefined,
          steps: [],
        };
      }
    }
  }, [selectedEditEquipment, selectedEquipment]);

  useEffect(() => {
    if (isInstructionsModalOpen.isOpen) {
      getLogger()?.track(
        loggerContent.LOGGER.SELF_SERVICE.HOW_DO_I_FIND(
          instructionsModalContent.steps[isInstructionsModalOpen.defaultStep || 0].title
        )
      );
    }
  }, [instructionsModalContent, isInstructionsModalOpen]);

  return (
    <div>
      {onboardingState.equipment.map((equipment, index) => (
        <EquipmentCard
          equipment={equipment}
          key={`${equipment.equipmentName ?? ''}${equipment.registrationCode ?? ''}${equipment.serialNumber ?? ''}`}
          index={index}
          onDeleteClick={onEquipmentCardDeleteClick}
          equipmentForm={equipmentForm}
          setSelectedEditEquipment={setSelectedEditEquipment}
        />
      ))}
      {checkIfDuplicatedNames(onboardingState.equipment) && (
        <FormHelperText error>{content.DUPLICATED_EQUIPMENT_NAME}</FormHelperText>
      )}
      {checkIfDuplicatedSerialNumbers(onboardingState.equipment) && (
        <FormHelperText error>{content.DUPLICATED_SERIAL_NUMBER}</FormHelperText>
      )}
      {!!onboardingState.equipment?.length && !disableFurtherRegistration && <Divider className={classes.divider} />}
      <EquipmentRegistration
        equipmentAmount={onboardingState.equipment.length}
        disableFurtherRegistration={disableFurtherRegistration}
        equipmentForm={equipmentForm({
          equipmentType: selectedEquipment,
          equipment: null,
          index: 0,
          isEditForm: false,
        })}
        tilesProps={{ selectedEquipment, onChange: setSelectedEquipment }}
      />
      <StepperModal handleClose={closeInstructionsModal} {...isInstructionsModalOpen} {...instructionsModalContent} />
    </div>
  );
};

interface IEquipmentRegistrationProps {
  tilesProps: { onChange: (id: EQUIPMENT_ENUM) => void; selectedEquipment: EQUIPMENT_ENUM | null };
  equipmentAmount: number;
  disableFurtherRegistration: boolean;
  equipmentForm: ReactElement;
}

const EquipmentRegistration = ({
  equipmentAmount,
  disableFurtherRegistration,
  equipmentForm,
  tilesProps,
}: IEquipmentRegistrationProps) => {
  if (disableFurtherRegistration) {
    return null;
  }

  if (equipmentAmount < equipmentLimit) {
    return (
      <>
        <EquipmentTiles {...tilesProps} />
        {equipmentForm}
      </>
    );
  }

  return <EquipmentLimit />;
};
