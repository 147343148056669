import { zodResolver } from '@hookform/resolvers/zod';
import { TOrganizationSchema } from '@marlin/account-data-access-organization';
import { FormField, Select } from '@marlin/shared/ui-form-common';
import { getLogger } from '@marlin/shared/utils/logger';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { content } from '../../../../content';
import { Buttons } from '../../../buttons/buttons.component';
import { TSelectOrganization, selectOrganizationSchema } from '../../../onboarding.schema';
import { useOnboardingState } from '../../../use-onboarding-state.hook';
import { ONBOARDING_STEP } from '../../steps';
import { useStyles } from '../setup-organization.styles';

export const SelectOrganization = ({
  organizations,
  onCancel,
  onNextStep,
}: {
  organizations: TOrganizationSchema[];
  onCancel: () => void;
  onNextStep: (step: ONBOARDING_STEP, completed?: ONBOARDING_STEP[]) => void;
}) => {
  const { classes } = useStyles();
  const { addOrganization, onboardingState } = useOnboardingState();

  const form = useForm<TSelectOrganization>({
    defaultValues: {
      organizationId: organizations[0].organizationId,
    },
    mode: 'onChange',
    resolver: zodResolver(selectOrganizationSchema),
  });
  const {
    handleSubmit,
    setValue,
    formState: { isValid },
  } = form;

  const onSubmit = (data: TSelectOrganization) => {
    getLogger()?.track(content.LOGGER.SELF_SERVICE.SELECT_ORGANIZATION_SUCCESS, {
      addOrganization: data.organizationId,
    });
    const organizationName =
      organizations.find((org) => org.organizationId === data.organizationId)?.organizationName ?? '';
    addOrganization({ data, organizationName });
    onNextStep(ONBOARDING_STEP.REGISTER_EQUIPMENT, [ONBOARDING_STEP.SETUP_ORGANIZATION]);
  };

  useEffect(() => {
    if (onboardingState.organizationId) {
      setValue('organizationId', onboardingState.organizationId);
    }
  }, [setValue, onboardingState.organizationId]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.inputsContainer}>
          <FormField<TSelectOrganization> fieldName="organizationId">
            {(field) => (
              <Select
                {...field}
                prefix="organizationId"
                required
                label={content.ORGANIZATION}
                className={classes.select}
                data={organizations.map((org) => ({ name: org.organizationName, id: org.organizationId }))}
              />
            )}
          </FormField>
        </div>
        <Buttons onNext={handleSubmit(onSubmit)} onCancel={onCancel} isDisabled={!isValid} />
      </form>
    </FormProvider>
  );
};
