import { MarlinTheme } from '@marlin/shared/theme';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  error: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.error.main,
    display: 'flex',
    alignItems: 'center',
  },

  errorText: {
    marginLeft: theme.typography.pxToRem(10),
  },
}));

interface IExportTelemetryErrorMessage {
  message: ReactNode;
}

export function ExportTelemetryErrorMessage({ message }: IExportTelemetryErrorMessage): JSX.Element | null {
  const { classes } = useStyles();

  if (!message) {
    return null;
  }

  return (
    <div className={classes.error}>
      <WarningRoundedIcon />
      <span className={classes.errorText}>{message}</span>
    </div>
  );
}
