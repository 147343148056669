import { dateAdapter } from '@marlin/shared/utils-common-date';
import { z } from 'zod';

import { content } from './content';

const maxDateRangeInDays = 31;

export const exportTelemetrySchema = z
  .object({
    fromDate: z.string().nullish(),
    toDate: z.string().nullish(),
    dateRange: z.string().nullish(),
  })
  .superRefine(({ fromDate, toDate }, ctx): never | undefined => {
    if (!fromDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: content.REQUIRED_ERROR,
        path: ['fromDate'],
      });

      return z.NEVER;
    }

    if (!toDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: content.REQUIRED_ERROR,
        path: ['toDate'],
      });

      return z.NEVER;
    }

    const jsFromDate = dateAdapter.date(fromDate)?.toDate();
    if (jsFromDate) {
      const jsFromDateYear = new Date(jsFromDate).getFullYear();
      if (jsFromDateYear < 2022) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: content.TO_LOW_DATE,
          path: ['dateRange'],
        });
      }
    }

    const jsToDate = dateAdapter.date(toDate)?.toDate();
    if (jsToDate) {
      const jsToDateYear = new Date(jsToDate).getFullYear();
      if (jsToDateYear < 1970) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: content.TO_LOW_DATE,
          path: ['dateRange'],
        });
      }
    }

    const toDateIsSameOrBeforeFromDate = dateAdapter.date(toDate)?.isSameOrBefore(dateAdapter.date(fromDate), 'days');
    const rangeInDays = dateAdapter.date(toDate)?.diff(dateAdapter.date(fromDate), 'days');

    if ((rangeInDays && rangeInDays > maxDateRangeInDays) || toDateIsSameOrBeforeFromDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: content.DATE_RANGE_ERROR(maxDateRangeInDays),
        path: ['dateRange'],
      });
    }

    return undefined;
  });

export type TExportTelemetryForm = z.infer<typeof exportTelemetrySchema>;
