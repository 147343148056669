import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Divider, IconButton, InputAdornment, Menu, TextField } from '@mui/material';
import { KeyboardEvent, useState } from 'react';
import { useCallback, useMemo } from 'react';
import { Virtuoso } from 'react-virtuoso';

import { EmptyOrganizationItem } from '../../utils/empty-organization-item';
import { IEntity } from '../../utils/types';
import { useEntityFilter } from '../../utils/use-entity-filter';
import { OrganizationItem } from '../organization-item';
import { content } from './content';
import { useStyles } from './organizations-list.styles';

interface IOrganizationsListProps {
  entityId?: string;
  entities: IEntity[];
  open: boolean;
  anchor: null | HTMLElement;
  onClose: () => void;
  onChange: (organizationId: string) => void;
}

const maxAllowedOrganizationsWithoutSearchInput = 10;
const heightOfListWithSearchInput = 340;
const listItemHeight = 37;
const virtualizedListOffset = 150;

const renderRow = (row: IEntity, currentOrgId: string, onChange: (organizationId: string) => void) => {
  return (
    <OrganizationItem
      isCurrentOrg={currentOrgId === row.id}
      key={row.id}
      onChange={() => onChange(row.id)}
      orgName={row.name}
      tierName={row.tier || ''}
    />
  );
};

export const OrganizationsList = ({ entityId, entities, open, anchor, onChange, onClose }: IOrganizationsListProps) => {
  const { classes } = useStyles();
  const [searchInputplaceholder, setSearchInputPlaceholder] = useState(content.SEARCH_PLACEHOLDER_BLURED);
  const { handleOrgSearch, clearSearchFilter, orgSearchValue, filteredOrgs } = useEntityFilter({
    entities: entities,
  });

  const handleClose = () => {
    clearSearchFilter();
    onClose();
  };

  const renderItems = useCallback(
    (index: number) => renderRow(filteredOrgs[index], entityId ?? '', onChange),
    [entityId, filteredOrgs, onChange]
  );

  const showSearchFilter = entities.length > maxAllowedOrganizationsWithoutSearchInput;

  const endAdornment = () => {
    if (orgSearchValue.length > 0) {
      return (
        <InputAdornment position="end">
          <IconButton onClick={clearSearchFilter}>
            <CloseRoundedIcon />
          </IconButton>
        </InputAdornment>
      );
    }
    return null;
  };

  const calculatedHeightOfOrgList = useMemo(() => {
    if (filteredOrgs.length < maxAllowedOrganizationsWithoutSearchInput) {
      return filteredOrgs.length * listItemHeight;
    }
    return heightOfListWithSearchInput;
  }, [filteredOrgs.length]);

  return (
    <Menu
      anchorEl={anchor}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      disableAutoFocusItem={true}
      className={classes.container}
    >
      {showSearchFilter && (
        <TextField
          variant="standard"
          onChange={handleOrgSearch}
          placeholder={searchInputplaceholder}
          value={orgSearchValue}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
            e.stopPropagation();
          }}
          onFocus={() => {
            setSearchInputPlaceholder(content.SEARCH_PLACEHOLDER_FOCUSED);
          }}
          onBlur={() => {
            setSearchInputPlaceholder(content.SEARCH_PLACEHOLDER_BLURED);
          }}
          autoFocus={false}
          className={classes.searchInput}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <IconButton>
                  <SearchRoundedIcon />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: endAdornment(),
          }}
        />
      )}

      <Divider />
      {filteredOrgs.length === 0 ? (
        <EmptyOrganizationItem />
      ) : (
        <Virtuoso
          style={{ height: calculatedHeightOfOrgList }}
          itemSize={() => listItemHeight}
          totalCount={filteredOrgs.length}
          itemContent={renderItems}
          increaseViewportBy={virtualizedListOffset}
        />
      )}
    </Menu>
  );
};
