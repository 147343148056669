import { getHttpClient } from '@marlin/shared/utils/react-query';

import { paths } from './api';
import {
  TExportTelemetryPayload,
  TExportTelemetryResponseSchema,
  exportTelemetryPayload,
  exportTelemetryResponseSchema,
} from './export-telemetry.schema';

export const exportTelementry = async (payload: TExportTelemetryPayload): Promise<TExportTelemetryResponseSchema> => {
  const payloadData = exportTelemetryPayload.parse(payload);
  const response = await getHttpClient().post(paths.EXPORT_TELEMETRY, payloadData);
  return exportTelemetryResponseSchema.parse(response);
};
