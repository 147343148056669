import { MarlinTheme } from '@marlin/shared/theme';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../../content';
import { ReactComponent as WorksWithNexa } from './works_with_nexa.svg';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    marginTop: theme.typography.pxToRem(32),
    marginBottom: theme.typography.pxToRem(32),
  },
}));

interface INoFormProps {
  equipmentLength: number;
}

export const NoForm = ({ equipmentLength }: INoFormProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      {!!equipmentLength && (
        <div>
          <Typography variant="caption" color="text.disabled">
            {content.SELECT_EQUIPMENT}
          </Typography>
        </div>
      )}
      <div className={classes.icon}>
        <WorksWithNexa />
      </div>
    </div>
  );
};
