import { useCurrentUser } from '@marlin/account-data-access-organization';
import { OrganizationSwitcherMobile } from '@marlin/account-ui-organization-switcher';
import { SLIDE, TEASER_MODAL_ACTION_TYPE, TeaserButton, useTeaserModal } from '@marlin/account/feature/teaser';
import { UnreadAlertsBadge } from '@marlin/alert/ui/alert-unread-badge';
import { environment } from '@marlin/environment';
import { useUserPortfolios } from '@marlin/portfolio-data-access-portfolio';
import { CollapsableLogo, FullLogo } from '@marlin/shared/theme';
import { PERMISSIONS, usePermission } from '@marlin/shared/utils-permission';
import { routes } from '@marlin/shared/utils-routes';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import { Drawer, IconButton, Toolbar } from '@mui/material';
import { FC, PropsWithChildren, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { Logout } from './components/logout.component';
import { NavLinks } from './components/nav-links.component';
import { content } from './content';
import { useStyles } from './mobile-side-nav.component.styles';
import { TSideNavElement } from './types';

interface IMobileSideNavProps extends PropsWithChildren {
  topNavLinks: TSideNavElement[];
  bottomNavLinks: TSideNavElement[];
  app?: 'marlin' | 'portfolio' | 'support';
}

// todo: use children in toolbar
export const MobileSideNav: FC<IMobileSideNavProps> = ({ topNavLinks, bottomNavLinks, app = 'marlin' }) => {
  const { classes } = useStyles({ appName: environment.app.name });
  const { data } = useCurrentUser();
  const { data: portfolioData } = useUserPortfolios();
  const { modalDispatch } = useTeaserModal();
  const allowSystemMap = usePermission(PERMISSIONS.SYSTEM_MAP);
  const [mobileOpen, setMobileOpen] = useState(false);
  const { pathname } = useLocation();

  const isInAlerts = pathname.includes(routes.alerts.root);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleUpgradeNexa = () => {
    handleDrawerToggle();
    modalDispatch({
      type: TEASER_MODAL_ACTION_TYPE.SHOW_TEASER_MODAL,
      payload: {
        slide: SLIDE.SYSTEM_MAP,
      },
    });
  };

  return (
    <>
      <Toolbar className={classes.toolbar}>
        <div className={classes.toolbarLeft}>
          <IconButton edge="start" onClick={handleDrawerToggle}>
            <MenuRoundedIcon />
          </IconButton>
        </div>
        {environment.app.name === 'Nexa' && <FullLogo />}

        <div className={classes.toolbarRight}>
          {app !== 'portfolio' ? (
            <>
              <OrganizationSwitcherMobile
                currentOrgId={data?.currentOrganization.organizationId}
                currentOrgName={data?.currentOrganization.organizationName}
                entities={
                  data?.organizations.map((org) => ({
                    id: org.organizationId,
                    name: org.organizationName,
                    tier:
                      org.features.tierId === '00000000-0000-0000-0000-000000000001'
                        ? content.NEXA_EQUIPMENT
                        : content.NEXA_SYSTEM,
                  })) || []
                }
                variant={app || 'marlin'}
              />
              {app !== 'support' ? (
                <NavLink to={routes.alerts.root}>
                  <IconButton edge="end" className={classes.badgeContainer}>
                    <UnreadAlertsBadge>
                      <NotificationsRoundedIcon color={isInAlerts ? 'primary' : 'inherit'} />
                    </UnreadAlertsBadge>
                  </IconButton>
                </NavLink>
              ) : null}
            </>
          ) : (
            <OrganizationSwitcherMobile
              currentOrgId={portfolioData?.currentPortfolio?.id}
              currentOrgName={portfolioData?.currentPortfolio?.name}
              entities={portfolioData?.portfolios || []}
              variant="portfolio"
            />
          )}
        </div>
      </Toolbar>
      <Drawer
        className={classes.wrapper}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        anchor="left"
      >
        <div className={classes.optionsWrapper}>
          <div>
            <div className={classes.headerRow}>
              {environment.app.name === 'Nexa' ? <FullLogo /> : <CollapsableLogo />}
              <IconButton edge="end" color="primary" onClick={handleDrawerToggle}>
                <CloseRoundedIcon />
              </IconButton>
            </div>
            <NavLinks
              isCollapsed={false}
              desktopNavbarLocked={true}
              navLinks={topNavLinks}
              onClick={handleDrawerToggle}
            />
          </div>
          <div className={classes.bottomWrapper}>
            {!allowSystemMap && app === 'marlin' && (
              <div className={classes.upgradeNexaBtn}>
                <TeaserButton onClick={handleUpgradeNexa} variant={'main'} text={content.UPGRADE_NEXA} />
              </div>
            )}
            <NavLinks
              isCollapsed={false}
              desktopNavbarLocked={true}
              navLinks={bottomNavLinks}
              onClick={handleDrawerToggle}
            />
            <Logout isCollapsed={false} desktopNavbarLocked={true} />
          </div>
        </div>
      </Drawer>
    </>
  );
};
