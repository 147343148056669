import { useDatapoints, useMetadata } from '@marlin/asset/data-access/equipment';
import { getDatapointWithUiMetadata, mergeDatapointsWithMetadata } from '@marlin/asset/shared/utils/datapoint-mappers';
import { TDatapoint, TDatapointWithMetadata } from '@marlin/shared/utils/datapoint-mappers';

export const useGatewayDatapoints = (
  equipmentId: string,
  datapointCallback?: (datapoint: TDatapointWithMetadata, allDatapoints: TDatapoint[]) => TDatapointWithMetadata
) => {
  const datapointsResponse = useDatapoints();
  const { data: metadataResponse, isLoading } = useMetadata({ equipmentId });

  const datapointsWithMetadata = mergeDatapointsWithMetadata(
    metadataResponse?.datapoints,
    datapointsResponse.datapoints,
    datapointCallback
  );

  const datapointWithUiMetadata = datapointsWithMetadata.map((datapoint) => getDatapointWithUiMetadata(datapoint));

  return {
    datapointWithUiMetadata,
    timestamp: datapointsResponse.lastReadingTime,
    isLoading,
  };
};
