import { getEnvConfig } from './env';

export type { IEnvConfig, TAppName } from './env';

type IAuthStorage = 'localStorage' | 'sessionStorage' | 'memoryStorage';

const storage: IAuthStorage = 'localStorage';
const envConfig = getEnvConfig();

export const environment = {
  production: false,
  apiUrl: envConfig.apiUrl,
  baseUrl: envConfig.baseUrl,
  environment: envConfig.environment,
  app: {
    name: envConfig.app.name,
  },
  module: {
    authorization: {
      issuer: `${envConfig.auth.instance}/${envConfig.auth.policyName}/`,
      clientId: envConfig.auth.clientId,
      allowedUrls: [envConfig.auth.instance],
      redirectUri: envConfig.auth.redirectUrl,
      logoutUri: envConfig.auth.logoutUri,
      scopes: envConfig.auth.scopes,
      setupAutomaticSilentRefresh: false,
      storage,
      b2cUrls: {
        edit: `${envConfig.auth.instance}/B2C_1A_UnifiedProfileEdit/`,
        resetPassword: `${envConfig.auth.instance}/B2C_1A_UnifiedPasswordReset/`,
      },
      baseUrl: envConfig.baseUrl,
    },
    mobile: {
      scopes: envConfig.mobile.scopes,
      authInstance: envConfig.mobile.instance,
      policyName: envConfig.mobile.policyName,
      buildNumber: envConfig.mobile.buildNumber,
      ios: {
        clientId: envConfig.mobile.ios.clientId,
        redirectUri: envConfig.mobile.ios.redirectUri,
      },
      android: {
        clientId: envConfig.mobile.android.clientId,
        redirectUri: envConfig.mobile.android.redirectUri,
      },
    },
    http: {
      baseHref: '',
      baseDomain: '',
      proxyConfig: {
        '/api': {
          target: envConfig.apiUrl.admin,
          secure: false,
          changeOrigin: true,
          logLevel: 'debug',
        },
        '/workflow/api': {
          replace: true,
          target: envConfig.apiUrl.workflow,
          secure: false,
          changeOrigin: true,
          logLevel: 'debug',
        },
        '/etl/api': {
          replace: true,
          target: envConfig.apiUrl.etl,
          secure: false,
          changeOrigin: true,
          logLevel: 'debug',
        },
        '/warrantyService': {
          replace: true,
          target: envConfig.apiUrl.warranty,
          secure: false,
          changeOrigin: true,
          logLevel: 'debug',
        },
        '/gateway': {
          replace: true,
          target: envConfig.apiUrl.gateway,
          secure: false,
          changeOrigin: true,
          logLevel: 'debug',
        },
        '/eventlog/api': {
          replace: true,
          target: envConfig.apiUrl.eventLog,
          secure: false,
          changeOrigin: true,
          logLevel: 'debug',
        },
      },
    },
    realtimeCommunication: {
      url: envConfig.apiUrl.realTimeCommunication,
    },
    analytics: {
      helpPageUrl: envConfig.analytics.helpPageUrl,
    },
    features: {
      hotSos: envConfig.features.hotSoS,
      waterHeater: envConfig.features.waterHeater,
      boiler: envConfig.features.boiler,
      portfolio: envConfig.features.portfolio,
      support: envConfig.features.support,
      mockMapsApi: envConfig.features.mockMapsApi,
      plant: envConfig.features.plant,
      gateway: envConfig.features.gateway,
      europeTimezones: envConfig.features.europeTimezones,
      sentinel: envConfig.features.sentinel,
      analyticsv2: envConfig.features.analyticsv2,
      paginatedOrganizationList: envConfig.features.paginatedOrganizationList,
    },
    automationHelper: {
      dataTestId: envConfig.automationHelper.dataTestId,
      allowedAttributes: envConfig.automationHelper.allowedAttributes,
      snackbarAutoHideDuration: envConfig.automationHelper.snackbarAutoHideDuration,
      extendedQaLogging: envConfig.automationHelper.extendedQaLogging,
    },
    googleMapsApiKey: envConfig.googleMapsApiKey,
    msBookingsUrl: envConfig.msBookingsUrl,
    logger: {
      token: envConfig.logger.token,
    },
  },
};
