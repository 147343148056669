import { useCurrentUser } from '@marlin/account-data-access-organization';
import { MarlinTheme } from '@marlin/shared/theme';
import { Chip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../mobile/content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  text: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 400,
  },
  nexaSystem: {
    color: theme.palette.secondary.main,
  },
  nexaEquipment: {
    color: theme.palette.primary.main,
  },
}));

interface IOrganizationTier {
  tier: string;
}

export const OrganizationTier = ({ tier }: IOrganizationTier) => {
  const { classes, cx } = useStyles();
  const { data } = useCurrentUser();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const textStyle = cx(classes.text, tier === content.NEXA_SYSTEM ? classes.nexaSystem : classes.nexaEquipment);

  if (!data?.currentOrganization.roles.includes('Support')) {
    return null;
  }

  if (isMobile) {
    return (
      <Chip
        color={tier === content.NEXA_EQUIPMENT ? 'primary' : 'secondary'}
        label={tier === content.NEXA_EQUIPMENT ? 'T1' : 'T2'}
      />
    );
  }
  return <Typography className={textStyle}>{tier.toUpperCase()}</Typography>;
};
