import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { useSearchParams } from '@marlin/shared/utils-router';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

interface ISettingRow {
  label: string;
  name: string;
  isLast?: boolean;
  dataTestId: string;
  children: ReactNode;
  isFirst?: boolean;
  displayedValue?: string;
  disabled?: boolean;
}

interface IStyles {
  isLast: boolean;
  isFirst: boolean;
  isEditing: boolean;
}

const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { isLast, isFirst, isEditing }) => {
  const borderStyle = `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`;

  return {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: isLast && !isEditing ? 'none' : borderStyle,
      borderRight: isEditing ? borderStyle : 'none',
      borderLeft: isEditing ? borderStyle : 'none',
      borderTop: isFirst && isEditing ? borderStyle : 'none',
      padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
      [theme.breakpoints.down('md')]: {
        display: 'grid',
        gridTemplateColumns: '5fr',
        gridTemplateRows: `1fr fit-content(${theme.typography.pxToRem(22)})`,
        gridTemplateAreas: `"title button" "value button"`,
        height: '100%',
        marginBottom: isLast ? theme.typography.pxToRem(16) : 'none',
        borderRight: 'none',
        borderLeft: 'none',
        padding: isEditing ? 0 : `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
      },
    },
    typographyValue: {
      gridArea: 'value',
      color: theme.palette.text.secondary,
    },
  };
});

const SettingRowInternal = ({
  children,
  isLast = false,
  isFirst = false,
  displayedValue,
  name,
  dataTestId,
  label,
}: ISettingRow) => {
  const [searchParams] = useSearchParams();
  const settingId = searchParams.get('settingId');
  const isEditing = Boolean(settingId) && settingId === name;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { classes } = useStyles({ isLast, isFirst, isEditing });

  return (
    <div className={classes.container} data-testid={dataTestId}>
      {!isEditing && (
        <div>
          <Typography variant="body1" data-testid="setting-list-item-title">
            {label}
          </Typography>
          {isMobile && displayedValue && (
            <Typography variant="body2" className={classes.typographyValue} data-testid="setting-list-item-value">
              {displayedValue}
            </Typography>
          )}
        </div>
      )}
      {children}
    </div>
  );
};

export const SettingRow = ({
  children,
  disabled,
  tooltipText,
  ...props
}: ISettingRow & {
  tooltipText?: string;
}) => {
  const theme = useTheme();
  const displayTooltipOnTop = useMediaQuery(theme.breakpoints.down('xl'));

  if (disabled && tooltipText) {
    return (
      <Tooltip text={tooltipText} placement={displayTooltipOnTop ? 'top' : 'right'}>
        <div>
          <SettingRowInternal {...props}>{children}</SettingRowInternal>
        </div>
      </Tooltip>
    );
  }

  return <SettingRowInternal {...props}>{children}</SettingRowInternal>;
};
