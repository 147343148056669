import { z } from 'zod';

import { OrganizationSchema, organizationRowSchema } from '../infrastructure/organization.schema';

interface IOrganizationWrapperAdapter {
  id: string;
  name: string;
  role: 'Support' | 'Admin' | 'Viewer';
}

export const organizationWrapperAdapterSchema = z.array(
  OrganizationSchema.transform(({ organizationId, organizationName, roles }): IOrganizationWrapperAdapter => {
    return {
      id: organizationId,
      name: organizationName,
      role: roles.includes('Support') ? 'Support' : roles.includes('Admin') ? 'Admin' : 'Viewer',
    };
  })
);

export const filteredorganizationWrapperAdapterSchema = z.array(
  organizationRowSchema.transform(({ organizationId, name, role }): IOrganizationWrapperAdapter => {
    return {
      id: organizationId,
      name,
      role,
    };
  })
);

export const organizationWrapperParamsSchema = z.object({
  search: z.string().optional(),
});

export type TFilteredOrganizationWrapperAdapter = z.infer<typeof filteredorganizationWrapperAdapterSchema>;
export type TOrganizationWrapperAdapter = z.infer<typeof organizationWrapperAdapterSchema>;
export type TOrganizationWrapperParams = z.infer<typeof organizationWrapperParamsSchema>;
