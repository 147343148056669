import { useCurrentUser } from '@marlin/account-data-access-organization';
import { useUserPortfolios } from '@marlin/portfolio-data-access-portfolio';
import { useMemo } from 'react';

import { content } from './content';
import { OrganizationSwitcher as OrganizationSwitcherUI } from './organization-switcher-ui.component';

interface IOrganizationSwitcherProps {
  isCollapsed: boolean;
  desktopNavbarLocked: boolean;
  variant: 'marlin' | 'portfolio' | 'support';
}

export const OrganizationSwitcher = ({ isCollapsed, desktopNavbarLocked, variant }: IOrganizationSwitcherProps) => {
  const { data: orgData } = useCurrentUser();
  const { data: portfolioData } = useUserPortfolios();

  const { entityId, entityName, entities } = useMemo(() => {
    if (variant === 'support') {
      return {
        entityId: portfolioData?.currentPortfolio?.id,
        entityName: content.MARLIN_SUPPORT,
        entities: [],
      };
    }
    if (variant === 'portfolio') {
      return {
        entityId: portfolioData?.currentPortfolio?.id,
        entityName: portfolioData?.currentPortfolio?.name,
        entities: portfolioData?.portfolios || [],
      };
    }
    return {
      entityId: orgData?.currentOrganization.organizationId,
      entityName: orgData?.currentOrganization.organizationName,
      entities: (orgData?.organizations || []).map((org) => ({
        id: org.organizationId,
        name: org.organizationName,
        tier:
          org.features.tierId === '00000000-0000-0000-0000-000000000001' ? content.NEXA_EQUIPMENT : content.NEXA_SYSTEM,
      })),
    };
  }, [
    orgData?.currentOrganization.organizationId,
    orgData?.currentOrganization.organizationName,
    orgData?.organizations,
    portfolioData?.currentPortfolio?.id,
    portfolioData?.currentPortfolio?.name,
    portfolioData?.portfolios,
    variant,
  ]);

  return (
    <OrganizationSwitcherUI
      variant={variant}
      isCollapsed={isCollapsed}
      desktopNavbarLocked={desktopNavbarLocked}
      entityId={entityId}
      entityName={entityName}
      entities={entities}
    />
  );
};
