import { ExportTelemetry as ExportTelemetryHub } from '@marlin/account/feature-export-telemetry';
import { PERMISSIONS, PermissionGuard } from '@marlin/shared/utils-permission';
import { routes } from '@marlin/shared/utils-routes';
import { Route } from 'react-router-dom';

export const ExportTelemetry = (): JSX.Element => {
  return (
    <Route
      path={routes.exportTelemetry.root}
      element={
        <PermissionGuard redirectTo={routes.home.root} permissions={[PERMISSIONS.DATA_EXPORT]}>
          <ExportTelemetryHub />
        </PermissionGuard>
      }
    />
  );
};
