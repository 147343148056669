import { useUpdateGatewaySettings } from '@marlin/asset/data-access/gateway';
import {
  ControlSwitcher,
  SettingsErrorPage,
  SettingsLeaveGuard,
  SettingsSectionAccordion,
  SettingsSectionSkeleton,
} from '@marlin/asset/ui/settings';
import { MarlinTheme } from '@marlin/shared/theme';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { useSearchParams } from '@marlin/shared/utils-router';
import { SETTINGS_GROUP, TEquipmentSetting } from '@marlin/shared/utils/datapoint-mappers';
import { useIdFromPathname } from '@marlin/shared/utils/url-params';
import { Backdrop } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';
import { useEditSettingContext } from '../../context/edit-settings.context';
import { useGatewaySettingsDatapoints } from '../../hooks/use-gateway-settings.hook';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    maxWidth: theme.typography.pxToRem(746),
  },
  loadingSpinnerWrapper: {
    minHeight: theme.typography.pxToRem(350),
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  backdrop: {
    zIndex: theme.indexPosition.foreground,
  },
}));

export const GatewaySettings = () => {
  const { classes } = useStyles();

  const [searchParams, setSearchParams] = useSearchParams();
  const settingGroupId = searchParams.get('settingGroupId');
  const settingsGroups = useMemo(
    () => [
      {
        id: SETTINGS_GROUP.SYSTEM,
        title: content.SYSTEM,
      },
    ],
    []
  );
  const editSettingContext = useEditSettingContext();

  const { enqueueSnackbar } = useSnackbar();

  const onSuccess = useCallback(() => {
    enqueueSnackbar(content.SAVE_SUCCESS_MSG, { variant: 'success', preventDuplicate: true });
  }, [enqueueSnackbar]);
  const onError = () => {
    enqueueSnackbar(content.ERROR, {
      variant: 'error',
      preventDuplicate: true,
    });
  };

  const gatewayId = useIdFromPathname();
  const {
    data: settings = [],
    isGatewaySettingsLoading,
    isError,
    isMetadataLoading,
    refetch,
  } = useGatewaySettingsDatapoints({
    gatewayId,
  });

  const updateSettingsMutation = useUpdateGatewaySettings({ gatewayId, onError, onSuccess });

  useEffect(() => {
    if (searchParams.has('settingGroupId') || searchParams.has('settingId')) {
      setSearchParams((currentParams) => {
        if (currentParams.has('settingGroupId')) {
          currentParams.delete('settingGroupId');
        }

        if (currentParams.has('settingId')) {
          currentParams.delete('settingId');
        }

        return currentParams;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateSettings = async (name: string, value: string, prevValue: string) => {
    await updateSettingsMutation.mutateAsync({ name, value, prevValue });
  };

  if (isError) {
    return <SettingsErrorPage onRefreshClick={refetch} />;
  }

  return (
    <div className={classes.container}>
      {settingsGroups.map(({ title, id }) => {
        return (
          <SettingsSectionAccordion title={title} id={id} key={id}>
            {id === settingGroupId ? (
              <SectionBody
                updateSettings={updateSettings}
                isSectionLoading={isGatewaySettingsLoading}
                datapoints={settings}
              />
            ) : null}
          </SettingsSectionAccordion>
        );
      })}
      <SettingsLeaveGuard saveSetting={updateSettings} {...editSettingContext} />
      <Backdrop className={classes.backdrop} open={isMetadataLoading || updateSettingsMutation.isLoading}>
        <LoadingSpinner />
      </Backdrop>
    </div>
  );
};

const SectionBody = ({
  updateSettings,
  isSectionLoading,
  datapoints,
}: {
  updateSettings: (name: string, value: string, prevValue: string) => Promise<void>;
  isSectionLoading: boolean;
  datapoints: TEquipmentSetting[];
}) => {
  const editSettingContext = useEditSettingContext();

  if (isSectionLoading) {
    return <SettingsSectionSkeleton />;
  }

  return (
    <ControlSwitcher datapoints={datapoints} updateSettings={updateSettings} {...editSettingContext} disabled={false} />
  );
};
