import { MarlinTheme } from '@marlin/shared/theme';
import LocationonRoundedIcon from '@mui/icons-material/LocationOnRounded';
import { Box, Grid, Typography } from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { HTMLAttributes } from 'react';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  iconContainer: {
    display: 'flex',
    width: theme.typography.pxToRem(32),
  },
  icon: {
    color: theme.palette.text.secondary,
  },
  detailsContainer: {
    width: `calc(100% - ${theme.typography.pxToRem(32)})`,
    wordWrap: 'break-word',
  },
  highlighted: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

interface IAutocompleteOptionProps {
  props: HTMLAttributes<HTMLLIElement>;
  option: google.maps.places.AutocompletePrediction;
  inputValue: string;
}

export const AutocompleteOption = ({ props, option, inputValue }: IAutocompleteOptionProps) => {
  const { classes, cx } = useStyles();
  const matches = match(option.structured_formatting.main_text, inputValue);
  const parts = parse(option.structured_formatting.main_text, matches);
  return (
    <li {...props}>
      <Grid container alignItems="center">
        <Grid item className={classes.iconContainer}>
          <LocationonRoundedIcon className={classes.icon} />
        </Grid>
        <Grid item className={classes.detailsContainer}>
          <div>
            {parts.map((part, index) => (
              <Box key={index} component="span" className={cx({ [classes.highlighted]: part.highlight })}>
                {part.text}
              </Box>
            ))}
          </div>
          <Typography variant="caption" color="text.secondary">
            {option.structured_formatting.secondary_text}
          </Typography>
        </Grid>
      </Grid>
    </li>
  );
};
