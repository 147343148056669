import { z } from 'zod';

export const EquipmentFormTypes = z.enum([
  'intellistation',
  'aercoBenchmark',
  'aercoInnovation',
  'sentinel',
  'other',
] as const);
export type TEquipmentFormTypes = z.infer<typeof EquipmentFormTypes>;
