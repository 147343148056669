export const content = {
  TITLE: 'Export',
  SUBTITLE: 'Export telemetry data as a one time operation',
  FORM_TITLE: 'Export Data Range',
  FORM_SUBTITLE: 'Maximum export time is 31 days. All times all local to your browser',
  EXPORT_BTN: 'Export to csv',
  FILE_EXPORTED: 'File has been exported',
  FILE_EXPORTING: 'File is exporting',
  FROM: 'From',
  TO: 'To',
  REQUIRED_ERROR: 'This is a required field',
  REVERSED_DATE: 'Incorrect date range. Dates were set chronologically.',
  TO_LOW_DATE: 'Incorrect date range. Minimal data is 2022.',
  NO_SENSORS_FOUND: "There are no sensors found in this organization. File hasn't been exported.",
  DATE_RANGE_ERROR: (numberOfDays: number) => `Reduce date range to ${numberOfDays} days or less.`,
  ERROR_MESSAGE: "Something went wrong. File hasn't been exported.",
};
