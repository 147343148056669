import { z } from 'zod';

export const Network = z.object({
  baudRate: z.number(),
  dataBits: z.number(),
  stopBits: z.number(),
  parity: z.string(),
});

const Address = z.object({
  address: z.number(),
  name: z.string().nullish(),
  password: z.string().nullish(),
  modelId: z.string(),
  locationId: z.string(),
});
const AddressUpdate = Address.extend({
  id: z.string().optional(),
});

const Connection = z.object({
  name: z.string().nullish(),
  network: Network,
  addresses: z.array(Address).nullable(),
});
const ConnectionUpdate = Connection.extend({
  id: z.string().optional(),
  addresses: z.array(AddressUpdate).nullable(),
});

export const GatewayPayloadBase = z.object({
  deviceId: z.string().uuid(),
  name: z.string().nullish(),
  description: z.string().nullish(),
  connections: z.array(Connection).nullable(),
});
export type TGatewayPayloadBase = z.infer<typeof GatewayPayloadBase>;

export const GatewayUpdatePayload = GatewayPayloadBase.extend({
  configurationId: z.string().uuid(),
  connections: z.array(ConnectionUpdate).nullable(),
});
export type TGatewayUpdatePayload = z.infer<typeof GatewayUpdatePayload>;

export const OperationError = z.object({
  errorCode: z.number(),
  description: z.string(),
});
export type TOperationError = z.infer<typeof OperationError>;

export const ExecuteOperationResponse = z.object({
  gatewayId: z.string().uuid(),
  configurationId: z.string().uuid(),
  operations: z.array(
    z.object({
      clientId: z.string().uuid(),
      connectionId: z.string().uuid(),
      addressId: z.string().uuid(),
      opCode: z.string(),
      errors: z.array(OperationError),
      timestamp: z.string(),
      telemetry: z.array(
        z.object({
          tag: z.string(),
          value: z.string().nullable(),
          error: OperationError.nullable(),
        })
      ),
    })
  ),
});

const ConnectionResult = z.array(
  z.object({
    connectionId: z.string().uuid(),
    addresses: z.array(
      z.object({
        addressId: z.string().uuid(),
        clientId: z.string().uuid(),
        operations: z.array(
          z.object({
            code: z.string(),
            timestamp: z.string(),
            errors: z.array(OperationError),
            telemetry: z.array(
              z.object({
                tag: z.string(),
                value: z.string().nullable(),
                error: OperationError.nullable(),
              })
            ),
          })
        ),
      })
    ),
  })
);

export const OperationResults = z.object({
  gatewayId: z.string().uuid(),
  configurationId: z.string().uuid(),
  connections: ConnectionResult,
});

export type TExecuteOperationResponse = z.infer<typeof ExecuteOperationResponse>;
export type TOperationResults = z.infer<typeof OperationResults>;
export type TConnectionResult = z.infer<typeof ConnectionResult>;
