import { MarlinTheme } from '@marlin/shared/theme';
import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(32),
  },
  select: {
    backgroundColor: theme.palette.background.primary,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.typography.pxToRem(32),
    marginTop: theme.typography.pxToRem(32),
    alignItems: 'flex-start',
    alignContent: 'flex-start',
  },
  sectionTitle: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold,
  },
  sectionSubtitle: {
    fontSize: theme.typography.pxToRem(16),
  },
  input: {
    backgroundColor: theme.palette.background.primary,
    '&> .MuiFormHelperText-root': {
      paddingLeft: theme.typography.pxToRem(5),
      marginLeft: 0,
      marginRight: 0,
      backgroundColor: theme.palette.background.secondary,
    },
  },
  tileContainer: {
    display: 'flex',
    gap: theme.typography.pxToRem(32),
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.typography.pxToRem(24),
    paddingBottom: theme.typography.pxToRem(24),
    [theme.breakpoints.down('md')]: {
      gap: theme.typography.pxToRem(8),
    },
  },
  tile: {
    display: 'flex',
    width: '100%',
    padding: theme.typography.pxToRem(8),
    fontSize: theme.typography.pxToRem(16),
    cursor: 'pointer',
    position: 'relative',
  },
  icon: {
    width: theme.typography.pxToRem(48),
    height: theme.typography.pxToRem(48),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.typography.pxToRem(8),
  },
  active: {
    backgroundColor: alpha(theme.palette.primary.main, 0.04),
    borderColor: theme.palette.primary.main,
  },
  checkIcon: {
    position: 'absolute',
    top: theme.typography.pxToRem(8),
    right: theme.typography.pxToRem(8),
    width: theme.typography.pxToRem(16),
    height: theme.typography.pxToRem(16),
  },
  singleTile: {
    width: '33%',
  },
}));
