import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: theme.typography.pxToRem(712),
    padding: theme.typography.pxToRem(24),
    rowGap: theme.typography.pxToRem(12),
  },

  pickersContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: theme.typography.pxToRem(12),
  },

  title: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.primary,
  },

  subtitle: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.secondary,
  },

  picker: {
    width: '50%',
  },

  progress: {
    color: theme.palette.background.primary,
    marginLeft: theme.typography.pxToRem(12),
  },
}));
