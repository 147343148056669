const telemetry = '/etl/api/devices';
const deviceTelemetry = `${telemetry}/{manufacturerId}/telemetry?hours={numberOfHours}`;
const baseAdminTelemetry = '/api/telemetry';
const rangeTelemetry = `${baseAdminTelemetry}/{manufacturerId}/range?from={from}&to={to}`;
const analyticsTelemetry = 'api/telemetry/analytics';

export const paths = {
  TELEMETRY: telemetry,
  DEVICE_TELEMETRY: deviceTelemetry,
  RANGE_TELEMETRY: rangeTelemetry,
  ANALYTICS_TELEMETRY: analyticsTelemetry,
};
