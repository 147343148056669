import { AlertSnackbar, IAlertNotification, IAlertSnackbar } from '@marlin/alert/ui/alert-snackbar';
import { environment } from '@marlin/environment';
import { MarlinTheme } from '@marlin/shared/theme';
import { SnoozeModalContainer } from '@marlin/shared/ui-domain-automation-snooze';
import { FlowLinkLoopSnackbar } from '@marlin/system-map/shared/ui-flow-link-loop-snackbar';
import { SnackbarProvider } from 'notistack';
import { ComponentType, PropsWithChildren, forwardRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useRealtimeCommunication } from './use-realtime-connection.hook.';

export const useSnackbarStyles = makeStyles()((theme: MarlinTheme) => ({
  containerRoot: {
    '&.notistack-SnackbarContainer': {
      zIndex: theme.zIndex.snackbar,
      maxWidth: theme.typography.pxToRem(460),
      [theme.breakpoints.down('md')]: {
        width: 'max-content',
        maxWidth: theme.typography.pxToRem(460),
      },
      [theme.breakpoints.down('sm')]: {
        width: 'max-content',
        maxWidth: `calc(100% - ${theme.typography.pxToRem(16)})`,
      },
    },
  },

  // TODO: Find better way to override the default snackbar class
  snackbar: {
    '& > div': {
      alignItems: 'start',
    },
    '&.notistack-MuiContent-warning': {
      color: theme.palette.warning.contrastText,
      backgroundColor: theme.palette.warning.main,
    },
    '&.notistack-MuiContent-success': {
      color: theme.palette.success.contrastText,
      backgroundColor: theme.palette.success.main,
    },
    '&.notistack-MuiContent-error': {
      color: theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.main,
    },
    '&.notistack-MuiContent-info': {
      color: theme.palette.info.contrastText,
      backgroundColor: theme.palette.info.main,
    },
  },
}));

/*
 * Providers that require initialized routing to work properly.
 * */

export const withRealtimeCommunication =
  (Cmp: ComponentType<PropsWithChildren>) =>
  (props: PropsWithChildren): JSX.Element => {
    useRealtimeCommunication();

    return <Cmp {...props} />;
  };

export const withSnackbar =
  (Cmp: ComponentType<PropsWithChildren>) =>
  (props: PropsWithChildren): JSX.Element => {
    const { classes } = useSnackbarStyles();

    const [alertToSnooze, setAlertToSnooze] = useState<IAlertNotification | null>(null);

    const clearAlertToSnooze = () => {
      setAlertToSnooze(null);
    };

    // TODO: REFINE ARCHITECTURE
    const alertSnackbarContainer = forwardRef<HTMLDivElement, Omit<IAlertSnackbar, 'setAlertToSnooze'>>(
      (props, ref) => {
        return <AlertSnackbar setAlertToSnooze={setAlertToSnooze} {...props} ref={ref} />;
      }
    );

    return (
      <SnackbarProvider
        Components={{ alertSnackbar: alertSnackbarContainer, loopSnackbar: FlowLinkLoopSnackbar }}
        classes={{ containerRoot: classes.containerRoot }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        maxSnack={10}
        className={classes.snackbar}
        autoHideDuration={environment.module.automationHelper.snackbarAutoHideDuration}
      >
        <Cmp {...props} />
        {alertToSnooze && (
          <SnoozeModalContainer
            automation={{
              ruleId: alertToSnooze.ruleId,
              ruleName: alertToSnooze.ruleName,
            }}
            deviceId={alertToSnooze.deviceId}
            closeModal={clearAlertToSnooze}
          />
        )}
      </SnackbarProvider>
    );
  };
