import { z } from 'zod';

import { LastReadingValue } from './equipment.model';

enum GATEWAY_MODE {
  Standby = 'Standby',
  Active = 'Active',
}
export const GatewayMode = z.nativeEnum(GATEWAY_MODE);

export const GatewayInfoUpdatePayload = z.object({
  name: z.string().optional(),
  description: z.string().nullish(),
  commissionDate: z.string().nullish(),
  locationId: z.string().nullish(),
});

export const GatewayInfoUpdateResponsePayload = GatewayInfoUpdatePayload.extend({
  id: z.string().optional(),
  mode: GatewayMode.nullish(),
  model: z.string(),
  manufacturerId: z.string().uuid(),
  lastReadingTime: z.string(),
  lastReadingValues: z.array(LastReadingValue),
  configurationId: z.string(),
  firmwareVersion: z.string().nullable(),
});

export type TGatewayInfoUpdatePayload = z.infer<typeof GatewayInfoUpdatePayload>;
export type TGatewayInfoUpdateResponsePayload = z.infer<typeof GatewayInfoUpdateResponsePayload>;
