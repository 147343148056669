import { useUpdateOrg } from '@marlin/account-data-access-organization';
import { useUpdatePortfolio } from '@marlin/portfolio-data-access-portfolio';
import { useDesktopSideNavbarCollapse } from '@marlin/shared/utils-common-desktop-sidenav';
import { adjustOrgName } from '@marlin/shared/utils-common-org-name';
import { useRouter } from '@marlin/shared/utils-router';
import { routes, routesPortfolio } from '@marlin/shared/utils-routes';
import UnfoldMoreTwoToneIcon from '@mui/icons-material/UnfoldMoreTwoTone';
import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

import { OrganizationSwitcherIcon } from '../components/organization-switcher-icon.component';
import { IEntity } from '../utils/types';
import { content } from './content';
import { OrganizationDetails } from './organization-details';
import { useStyles } from './organization-switcher-ui.styles';
import { OrganizationsList } from './organizations-list';

interface IOrganizationSwitcherUIProps {
  entityId?: string;
  entityName?: string;
  entities: IEntity[];
  isCollapsed: boolean;
  desktopNavbarLocked: boolean;
  variant: 'marlin' | 'portfolio' | 'support';
}

const allowedCharactersInOrgName = 26;

export const OrganizationSwitcher = ({
  entityName,
  entityId,
  entities,
  isCollapsed,
  desktopNavbarLocked,
  variant,
}: IOrganizationSwitcherUIProps) => {
  const { classes } = useStyles({ variant });

  const { enqueueSnackbar } = useSnackbar();
  const onSuccess = () =>
    enqueueSnackbar(content.ORGANIZATION_HAS_BEEN_CHANGED, {
      variant: 'success',
      preventDuplicate: true,
    });

  const onPortfolioSuccess = (name?: string) =>
    enqueueSnackbar(content.PORTFOLIO_HAS_BEEN_CHANGED(name), {
      variant: 'success',
      preventDuplicate: true,
    });

  const updateOrgMutation = useUpdateOrg({ shouldRefetch: true, onSuccess });
  const updatePortfolioMutation = useUpdatePortfolio({
    shouldRefetch: true,
    onSuccess: onPortfolioSuccess,
  });
  const { goTo } = useRouter();
  const { handleCollapseDesktopNavbar } = useDesktopSideNavbarCollapse();
  const [organizationDetailsAnchor, setOrganizationDetailsAnchor] = useState<HTMLElement | null>(null);
  const [organizationsListAnchor, setOrganizationListAnchor] = useState<HTMLElement | null>(null);

  const organizationDetailsOpen = Boolean(organizationDetailsAnchor);
  const organizationsListOpen = Boolean(organizationsListAnchor);

  const handleOpenOrganizationDetails = (event: React.MouseEvent<HTMLElement>) => {
    setOrganizationDetailsAnchor(event.currentTarget);
  };

  const handleOpenOrganizationsList = (event: React.MouseEvent<HTMLElement>) => {
    if (event.currentTarget.parentElement) {
      if (event.currentTarget.parentElement.parentElement) {
        setOrganizationListAnchor(event.currentTarget.parentElement.parentElement.parentElement);
      }
    }
  };

  const handleClose = () => {
    setOrganizationDetailsAnchor(null);
    setOrganizationListAnchor(null);
    handleCollapseDesktopNavbar();
  };

  const handleChangeEntity = (orgId: string) => {
    switch (variant) {
      case 'marlin': {
        updateOrgMutation.mutateAsync(orgId).then(() => {
          goTo(routes.home.root);
        });
        break;
      }
      case 'portfolio': {
        updatePortfolioMutation.mutateAsync(orgId).then(() => {
          goTo(routesPortfolio.home.root);
        });
        break;
      }
    }
    setOrganizationDetailsAnchor(null);
    setOrganizationListAnchor(null);
  };

  return (
    <div data-testid="organization-switcher">
      <div onClick={handleOpenOrganizationDetails} className={classes.container}>
        <OrganizationSwitcherIcon variant={variant} />
        {desktopNavbarLocked
          ? entityName && (
              <Typography noWrap className={classes.orgName} data-testid="current-organization">
                {adjustOrgName(entityName, allowedCharactersInOrgName)}
              </Typography>
            )
          : !isCollapsed &&
            entityName && (
              <Typography noWrap className={classes.orgName} data-testid="current-organization">
                {adjustOrgName(entityName, allowedCharactersInOrgName)}
              </Typography>
            )}
        <UnfoldMoreTwoToneIcon className={classes.orgIcon} />
      </div>
      <OrganizationDetails
        entities={entities}
        entityName={entityName}
        open={organizationDetailsOpen}
        anchor={organizationDetailsAnchor}
        onChange={handleOpenOrganizationsList}
        onClose={handleClose}
        variant={variant}
      />
      <OrganizationsList
        entityId={entityId}
        entities={entities}
        open={organizationsListOpen}
        anchor={organizationsListAnchor}
        onChange={handleChangeEntity}
        onClose={handleClose}
      />
    </div>
  );
};
