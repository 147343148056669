import { environment } from '@marlin/environment';
import { DeviceUserMeasuresPreferences, uomSettingsService } from '@marlin/shared/utils-format-reading';
import { getHttpClient } from '@marlin/shared/utils/react-query';

import { TUser, User } from '../schemas/user';
import { IEditedUser, IUserResponse } from '../user.model';
import { userService } from '../user.service';
import { getCurrentOrg } from '../utils/organization.utils';
import { paths } from './api';
import { getCurrentUserOrganization } from './organization';
import { OrganizationResponseSchema, TOrganizationResponseSchema } from './organization.schema';

const getOrgs = async () => {
  if (environment.module.features.paginatedOrganizationList) {
    const currentOrganizationResponse = await getCurrentUserOrganization();
    const [currentOrganization] = currentOrganizationResponse.organizations;

    return { user: currentOrganizationResponse, currentOrganization };
  }

  const res = await getHttpClient().get<unknown, IUserResponse>(paths.ORGANIZATION);

  const user = OrganizationResponseSchema.parse(res);
  const currentOrganization = getCurrentOrg(user.organizations);

  return {
    user,
    currentOrganization,
  };
};

export const getCurrentUser = async (): Promise<TOrganizationResponseSchema> => {
  const { user, currentOrganization } = await getOrgs();

  uomSettingsService.setUomSettings(DeviceUserMeasuresPreferences.parse(user.settings));

  userService.setUser({
    email: user.email,
    id: user.id,
    organizationId: currentOrganization.organizationId,
  });

  return {
    ...user,
    currentOrganization,
  };
};

export const getUser = async ({ userId }: { userId: string }) => {
  const res = await getHttpClient().get(`/api/User/{userId}`, { params: { userId } });

  return User.parse(res);
};

export const editUser = async ({ userId, newUser }: { userId: string; newUser: Omit<TUser, 'id'> }) => {
  const res = await getHttpClient().put<unknown, IEditedUser>(`/api/User/{userId}`, newUser, { params: { userId } });

  return User.parse(res);
};
