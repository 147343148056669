import { MarlinTheme } from '@marlin/shared/theme';
import { warrantyStorageService } from '@marlin/shared/utils-common-warranty-storage';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Paper, Typography, alpha } from '@mui/material';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { EQUIPMENT_ENUM } from '../../onboarding.schema';
import { ITile, getEquipmentTilesConfig } from './equipment.config';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  tileContainer: {
    display: 'flex',
    gap: theme.typography.pxToRem(24),
    marginBottom: theme.typography.pxToRem(24),
    [theme.breakpoints.down('md')]: {
      gap: theme.typography.pxToRem(8),
    },
  },
  tile: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.typography.pxToRem(8),
    cursor: 'pointer',
    position: 'relative',
  },
  icon: {
    width: theme.typography.pxToRem(48),
    height: theme.typography.pxToRem(48),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.typography.pxToRem(8),
  },
  active: {
    backgroundColor: alpha(theme.palette.primary.main, 0.04),
    borderColor: theme.palette.primary.main,
  },
  checkIcon: {
    position: 'absolute',
    top: theme.typography.pxToRem(8),
    right: theme.typography.pxToRem(8),
    width: theme.typography.pxToRem(16),
    height: theme.typography.pxToRem(16),
  },
  singleTile: {
    width: '33%',
  },
}));

interface ITileProps extends ITile {
  isActive?: boolean;
  onClick: (id: EQUIPMENT_ENUM) => void;
  isSingle?: boolean;
}

const Tile = ({ id, name, icon, isActive, onClick, isSingle = false }: ITileProps) => {
  const { classes, cx } = useStyles();

  return (
    <Paper
      variant="outlined"
      className={cx(classes.tile, { [classes.active]: isActive, [classes.singleTile]: isSingle })}
      onClick={() => onClick(id)}
      data-testid={`equipment-tile-${id}`}
    >
      <div className={classes.icon}>{icon}</div>
      <Typography variant="caption" align="center">
        {name}
      </Typography>
      {isActive && <CheckCircleRoundedIcon className={classes.checkIcon} color="primary" />}
    </Paper>
  );
};

interface IEquipmentTilesProps {
  onChange: (id: EQUIPMENT_ENUM) => void;
  selectedEquipment: EQUIPMENT_ENUM | null;
}

export const EquipmentTiles = ({ onChange, selectedEquipment }: IEquipmentTilesProps) => {
  const { classes } = useStyles();

  const warrantyModel = warrantyStorageService.get();

  const tilesConfig = useMemo(() => {
    return getEquipmentTilesConfig(warrantyModel);
  }, [warrantyModel]);

  const handleTileClick = (id: EQUIPMENT_ENUM) => {
    onChange(id);
  };

  return (
    <div className={classes.tileContainer}>
      {tilesConfig.map((tile) => (
        <Tile
          key={tile.id}
          id={tile.id}
          name={tile.name}
          icon={tile.icon}
          isActive={selectedEquipment === tile.id}
          onClick={handleTileClick}
          isSingle={tilesConfig.length === 1}
        />
      ))}
    </div>
  );
};
