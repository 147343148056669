import { createDateString } from '@marlin/shared/utils-common-date';
import { TUnitOfMeasure, UnitOfMeasureType, parseDisplayedValue } from '@marlin/shared/utils-format-reading';
import {
  AlertCauseSchema,
  CriticalitySchema,
  DeviceTypeResponseSchema,
  DeviceTypeSchemaParser,
} from '@marlin/system-map/shared/data-access-schemas';
import { z } from 'zod';

export const readingSchema = z
  .object({
    eventDateTime: z.string(),
    value: z.number(),
    uoM: UnitOfMeasureType.nullish(),
    batteryLevel: z.number(),
    manufacturerId: z.string(),
    deviceType: DeviceTypeResponseSchema,
    formattedReading: z.string().nullish(),
  })
  .transform((reading) => {
    const deviceType = DeviceTypeSchemaParser.parse(reading.deviceType);
    const eventDateTime = createDateString(reading.eventDateTime);

    return {
      ...reading,
      eventDateTime,
      deviceType,
    };
  });

export const chartDataSchema = z
  .object({
    eventDateTime: z.string(),
    value: z.number().nullish(),
    uoM: UnitOfMeasureType.nullish(),
  })
  .transform((chartData) => ({
    ...chartData,
    eventDateTime: createDateString(chartData.eventDateTime),
  }));

export const alertDataSchema = z
  .object({
    alertId: z.string(),
    ruleId: z.string(),
    startTime: z.string(),
    resolveTime: z.string().nullish(),
    readingValue: z.number(),
    thresholdMax: z.number().nullish(),
    thresholdMin: z.number().nullish(),
    alertCause: AlertCauseSchema,
    criticality: CriticalitySchema.nullish(),
    uoM: UnitOfMeasureType.nullable(),
  })
  .transform((chartData) => ({
    ...chartData,
    resolveTime: chartData.resolveTime ? createDateString(chartData.resolveTime) : null,
    startTime: createDateString(chartData.startTime),
    readingValue: chartData.readingValue,
  }));

const transformUomReading = (reading: TReading, uom: TUnitOfMeasure): TReading => {
  return {
    ...reading,
    uoM: uom,
    formattedReading: parseDisplayedValue(reading.value.toString(), uom, undefined, 'detail'),
  };
};

const transformChartData = <T extends { uoM?: TUnitOfMeasure | null }>(
  chartData: T,
  uom?: TUnitOfMeasure | null
): T => {
  return {
    ...chartData,
    uoM: uom,
  };
};

export const telemetrySchema = z
  .object({
    recent: readingSchema.nullish(),
    low: readingSchema.nullish(),
    high: readingSchema.nullish(),
    chartData: z.array(chartDataSchema).nullish(),
    alertData: z.array(alertDataSchema).nullish(),
    uoM: UnitOfMeasureType,
  })
  .transform((telemetry) => {
    const uom = telemetry.recent?.deviceType === 'LEAK' ? 'WaterDetect' : telemetry.uoM;
    return {
      ...telemetry,
      recent: telemetry.recent && uom ? transformUomReading(telemetry.recent, uom) : null,
      low: telemetry.low && uom ? transformUomReading(telemetry.low, uom) : null,
      high: telemetry.high && uom ? transformUomReading(telemetry.high, uom) : null,
      chartData: telemetry.chartData?.map((chartData) => transformChartData<TChartData>(chartData, uom)),
      alertData: telemetry.alertData?.map((alertData) => transformChartData<TAlertChartData>(alertData, uom)),
    };
  });

export type TReading = z.infer<typeof readingSchema>;
export type TChartData = z.infer<typeof chartDataSchema>;
export type TTelemetry = z.infer<typeof telemetrySchema>;
export type TAlertChartData = z.infer<typeof alertDataSchema>;
