import { TUseAnalyticsTelemetryReturnedType, useAnalyticsTelemetry } from '@marlin/asset/data-access/telemetry';
import { MarlinTheme } from '@marlin/shared/theme';
import {
  IChartData,
  IChartSeries,
  RANGE_FILTER,
  TChartDisplayType,
  TDurationDetails,
  findMinMax,
  getBucketSize,
  getDataWithoutMargins,
  getNumberOfBuckets,
  parseToChartData,
  useCustomPeriodModalContextStore,
  useDuration,
} from '@marlin/shared/utils-chart';
import { TAnalyticsTelemetryResponse, getFormattedValue } from '@marlin/shared/utils-format-reading';
import { useTheme } from '@mui/material';
import orderBy from 'lodash/orderBy';
import partition from 'lodash/partition';
import moment from 'moment/moment';
import { useCallback, useMemo } from 'react';

import { useSetDurationDetailsStore } from '../context/duration-details.context';
import { useMultiChartsStore } from '../context/multi-charts.context';
import { useSelectedSensorsStore } from '../context/selected-sensors.context';
import { TChartType, TSelectedDatapoint } from '../types';
import { onZoomChange } from '../utils/bucket-options';
import { getChartName } from '../utils/get-chart-icon-name';
import { useBucketOptions } from './use-bucket-options.hook';

interface IExtendedIChartSeries extends IChartSeries {
  parentType: TSelectedDatapoint['parentType'];
}

interface IUseChartData {
  chartId: string;
  chartType: TChartType;
  chartDisplayType: TChartDisplayType;
}

interface ITelemetryDeviceParam {
  manufacturerId: string;
  datapoints: string[];
}

type TTelemetryData = TUseAnalyticsTelemetryReturnedType['telemetryData'];

export const useChartData = ({ chartId, chartType, chartDisplayType }: IUseChartData) => {
  const theme = useTheme<MarlinTheme>();
  const [rangeFilter] = useMultiChartsStore((store) => store.rangeFilter);
  const [selectedSensors] = useSelectedSensorsStore((store) => store);
  const [averagingFunctionFilter] = useMultiChartsStore((store) => store.averagingFunctionFilter);
  const setDurationDetailsStore = useSetDurationDetailsStore();
  const { bucketOption, setBucketOption } = useBucketOptions(chartId);

  const [fromDateModal] = useCustomPeriodModalContextStore((store) => store.fromDate);
  const [toDateModal] = useCustomPeriodModalContextStore((store) => store.toDate);

  const chartDatapoints = useMemo(() => selectedSensors?.[chartId]?.chartDatapoints || [], [selectedSensors, chartId]);

  const updateDetails = useCallback(
    (key: string, details: TDurationDetails<string>[string]) => {
      setDurationDetailsStore({ [key]: details });
    },
    [setDurationDetailsStore]
  );

  const {
    fromDate: from,
    toDate: to,
    maxSelection,
    minSelection,
    isZoomed = false,
    handleZoomChange: handleDurationZoomChange,
  } = useDuration<string>({
    key: chartId,
    rangeFilter,
    fromDate: fromDateModal,
    toDate: toDateModal,
    updateDetails,
  });

  const parsedActiveDatapoints = useMemo((): ITelemetryDeviceParam[] => {
    return chartDatapoints.reduce<ITelemetryDeviceParam[]>((acc, { manufacturerId, name, isActive }) => {
      const isManufacturerAlreadyAdded = acc.findIndex((item) => item.manufacturerId === manufacturerId) > -1;

      if (isManufacturerAlreadyAdded) {
        return acc.map((manufaturerData) =>
          manufaturerData.manufacturerId === manufacturerId
            ? {
                manufacturerId: manufaturerData.manufacturerId,
                datapoints: [...manufaturerData.datapoints, name],
              }
            : manufaturerData
        );
      }

      return [...acc, { manufacturerId, datapoints: [name] }];
    }, []);
  }, [chartDatapoints]);

  const numberOfBuckets = useMemo(
    () =>
      getNumberOfBuckets({
        minSelection,
        maxSelection,
        rangeFilter: rangeFilter.range,
        shouldHaveSpecialCalculations: chartType === 'flow',
        bucketOption,
      }),
    [minSelection, maxSelection, rangeFilter.range, chartType, bucketOption]
  );

  const bucketPerMinute = useMemo(() => getBucketSize(from, to, numberOfBuckets), [from, numberOfBuckets, to]);

  const { data, isFetching, isInitialLoading } = useAnalyticsTelemetry({
    dateFrom: from,
    dateTo: to,
    requestedTelemetry: parsedActiveDatapoints,
    numberOfBuckets,
    averagingFunctionFilter,
    chartDisplayType,
    bucketOption,
    isCustomPeriod: rangeFilter.range === RANGE_FILTER.CUSTOM || isZoomed,
  });

  const activeDatapointsLowered = useMemo(
    () =>
      parsedActiveDatapoints.map(({ manufacturerId, datapoints }) => ({
        manufacturerId,
        datapoints: datapoints.map((item) => item.toLowerCase()),
      })),
    [parsedActiveDatapoints]
  );

  const squashedData = useMemo(() => squashEmptyValues(data?.telemetryData, chartDisplayType), [data]);

  const datapointsRawData = useMemo<TTelemetryData>(
    () =>
      squashedData.filter(
        (item) =>
          activeDatapointsLowered.find(
            (activeItem) =>
              activeItem.manufacturerId === item.manufacturerId &&
              activeItem.datapoints.includes(item.datapointName.toLowerCase())
          ) &&
          chartDatapoints.find(
            (chartDatapoint) =>
              item.manufacturerId === chartDatapoint.manufacturerId &&
              item.datapointName === chartDatapoint.name &&
              chartDatapoint.isActive
          )
      ),
    [activeDatapointsLowered, chartDatapoints, squashedData]
  );

  const convertedDatapoints = useMemo(
    () => parseToChartData(datapointsRawData, chartDisplayType, bucketPerMinute, minSelection, maxSelection),
    [datapointsRawData, chartDisplayType, bucketPerMinute, minSelection, maxSelection]
  );

  const chartData = useMemo<IChartSeries[]>(() => {
    const convertedChartData: IExtendedIChartSeries[] = convertedDatapoints.map(
      ({ datapointName, values, unitOfMeasure, manufacturerId }) => {
        const datapointWithMetadata = chartDatapoints.find(
          (item) => item.manufacturerId === manufacturerId && item.name.toLowerCase() === datapointName.toLowerCase()
        );

        return {
          name: datapointWithMetadata?.equipmentName
            ? `${datapointWithMetadata?.label} - ${datapointWithMetadata?.equipmentName}`
            : datapointWithMetadata?.label ?? datapointName,
          data: values || [],
          color: datapointWithMetadata?.color ?? theme.palette.charting.aquaFusion,
          type: chartDisplayType,
          uom: unitOfMeasure ?? null,
          id: datapointWithMetadata?.equipmentName
            ? `${datapointWithMetadata?.name} - ${datapointWithMetadata?.manufacturerId}`
            : datapointName,
          parentType: datapointWithMetadata?.parentType || 'sensor',
        };
      }
    );

    return sortData(convertedChartData);
  }, [convertedDatapoints, chartDatapoints, theme.palette.charting.aquaFusion, chartDisplayType]);

  const chartDataWithoutMargins = useMemo(
    () => getDataWithoutMargins(chartData, minSelection, maxSelection),
    [chartData, maxSelection, minSelection]
  );

  const { maxTotalVolumeForTimestamp, totalVolume } = useMemo(
    () => getTotalVolume({ minSelection, maxSelection, chartType, chartData }),
    [chartData, chartType, maxSelection, minSelection]
  );

  const { max, min } = useMemo(
    () =>
      findMinMax(
        [...chartDataWithoutMargins.reduce<IChartData[]>((acc, datapoint) => [...acc, ...datapoint.data], [])],
        maxTotalVolumeForTimestamp,
        chartDisplayType === 'bar'
      ),
    [chartDataWithoutMargins, maxTotalVolumeForTimestamp, chartDisplayType]
  );

  const handleZoomChange = useCallback(
    (zoom?: { min: number; max: number }) => {
      handleDurationZoomChange(zoom);

      if (zoom?.min && zoom?.max && bucketOption) {
        const dateDifferenceInMinutes = moment(zoom?.max).diff(moment(zoom?.min), 'minutes');
        onZoomChange(bucketOption, setBucketOption, dateDifferenceInMinutes);
      }
    },
    [bucketOption, handleDurationZoomChange, setBucketOption]
  );

  return {
    chartData,
    chartDataWithoutMargins,
    to: maxSelection,
    from: minSelection,
    isLoading: isInitialLoading,
    isFetching,
    max,
    min,
    totalVolume,
    name: selectedSensors?.[chartId]?.chartName ?? getChartName(chartType),
    handleZoomChange,
    isZoomed,
  };
};

const sortData = (data: IExtendedIChartSeries[]): IChartSeries[] => {
  const [sensorsInOrder, equipmentDatapointsInOrder] = partition(
    orderBy(data, 'name'),
    ({ parentType }) => parentType === 'sensor'
  );

  return [...sensorsInOrder, ...equipmentDatapointsInOrder];
};

const getTotalVolume = ({
  chartData,
  chartType,
  maxSelection,
  minSelection,
}: {
  chartData: IChartSeries[];
  chartType: TChartType;
  minSelection: number;
  maxSelection: number;
}) => {
  if (chartType !== 'flow') {
    return { totalVolume: null, maxTotalVolumeForDatapoint: null };
  }

  const uom = chartData.find((datapoint) => datapoint.data.length)?.uom;
  const minSelectionMinusMargin = minSelection - 1000;
  const maxSelectionPlusMargin = maxSelection + 1000;

  const maxYaxisValue: Record<number, number> = {};
  const allData = chartData
    .map((datapoint) => datapoint.data)
    .flat()
    .filter((point) => point.y && point.x >= minSelectionMinusMargin && point.x <= maxSelectionPlusMargin);

  const totalVolume = allData.reduce((acc, point) => {
    if (!point.y) return acc;
    const currentMax = maxYaxisValue[point.x] || 0;
    maxYaxisValue[point.x] = currentMax + point.y;

    return acc + point.y;
  }, 0);

  const totalVolumeDisplayValue = getFormattedValue(String(totalVolume), uom);

  const allMaxes = Object.values(maxYaxisValue).filter((value) => !isNaN(value));

  const maxTotalVolumeForTimestamp = allMaxes.length ? Math.max(...allMaxes) : 0;

  return { totalVolume: totalVolumeDisplayValue, maxTotalVolumeForTimestamp };
};

const squashEmptyValues = (
  telemetryData: TAnalyticsTelemetryResponse['telemetryData'] | undefined,
  chartDisplayType: TChartDisplayType
): TAnalyticsTelemetryResponse['telemetryData'] => {
  if (!telemetryData || !telemetryData.length) return [];

  if (chartDisplayType === 'bar') return telemetryData;

  const dataLength = telemetryData[0].values.length;
  const areLengthsEqual = telemetryData.every((data) => data.values.length === telemetryData[0].values.length);

  if (!areLengthsEqual) return telemetryData;

  const indexesToSquash: Array<number> = [];

  const checkIfShouldSquash = (datapoint: TAnalyticsTelemetryResponse['telemetryData'][0], index: number) =>
    datapoint.values[index].y === undefined &&
    datapoint.values[index - 1].y === undefined &&
    datapoint.values[index + 1].y === undefined;

  for (let i = 1; i < dataLength - 1; i++) {
    const shouldSquashValue = checkIfShouldSquash(telemetryData[0], i);

    if (shouldSquashValue) {
      const shouldSquashValueInAll = telemetryData.every((data) => checkIfShouldSquash(data, i));

      if (shouldSquashValueInAll) {
        indexesToSquash.push(i);
      }
    }
  }

  return telemetryData.map((datapoint) => ({
    ...datapoint,
    values: datapoint.values.filter((_, index) => !indexesToSquash.includes(index)),
  }));
};
