import { TUnitOfMeasure, apiUnitOfMeasuresToDevice } from '@marlin/shared/utils-format-reading';

export const getUomWithoutPerMin = (uom: TUnitOfMeasure | null) => {
  switch (uom) {
    case 'lpm':
      return apiUnitOfMeasuresToDevice.get('LITERS');
    case 'gpm':
      return apiUnitOfMeasuresToDevice.get('GALLONS');
    case 'm3ph':
      return apiUnitOfMeasuresToDevice.get('METERS');
    default:
      return null;
  }
};
