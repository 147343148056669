import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { MenuItem, Typography } from '@mui/material';
import React from 'react';

import { OrganizationTier } from '../../components/organization-tier.component';
import { useStyles } from './organization-item.styles';

interface IOrganizationItemProps {
  isCurrentOrg: boolean;
  orgName?: string;
  style?: React.CSSProperties;
  onChange: () => void;
  showFullName?: boolean;
  tierName: string;
}

const copy = (orgName: string) => navigator?.clipboard.writeText(orgName);

export const OrganizationItem = ({
  isCurrentOrg,
  orgName,
  style,
  onChange,
  showFullName = false,
  tierName,
}: IOrganizationItemProps) => {
  const { classes } = useStyles();

  const handleItemClick = () => {
    onChange();
    if (isCurrentOrg) {
      if (orgName) {
        copy(orgName);
      }
    }
  };

  return (
    <MenuItem style={style} onClick={handleItemClick} className={classes.container}>
      <div className={classes.orgNameContainer}>
        {isCurrentOrg && <FiberManualRecordIcon className={classes.greenDot} />}
        {orgName && (
          <div>
            {showFullName ? (
              <Typography className={classes.orgNameFull} data-testid="current-organization">
                {orgName}
              </Typography>
            ) : (
              <Typography noWrap className={classes.orgName} data-testid="current-organization">
                {orgName}
              </Typography>
            )}
          </div>
        )}
      </div>
      <OrganizationTier tier={tierName} />
    </MenuItem>
  );
};
