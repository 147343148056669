import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Divider, IconButton, InputAdornment, TextField } from '@mui/material';
import React, { KeyboardEvent, useCallback, useMemo, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';

import { EmptyOrganizationItem } from '../../utils/empty-organization-item';
import { IEntity } from '../../utils/types';
import { useEntityFilter } from '../../utils/use-entity-filter';
import { content } from '../content';
import { OrganizationItem } from '../organization-item';
import { useStyles } from './organizations-list.styles';

interface IOrganizationsListProps {
  entityId?: string;
  entities: IEntity[];
  onChange: (organizationId: string) => void;
}

const maxAllowedOrganizationsWithoutSearchInput = 5;
const heightOfListWithSearchInput = 340;
const widthOfListWith = 280;
const listItemHeight = 40;
const virtualizedListOffset = 150;

interface IRenderRowProps {
  data: IEntity;
  index: number;
}

const renderRow = ({ data }: IRenderRowProps, currentOrgId: string, onChange: (organizationId: string) => void) => {
  return (
    <OrganizationItem
      isCurrentOrg={currentOrgId === data.id}
      key={data.id}
      onChange={() => onChange(data.id)}
      orgName={data.name}
      tierName={data.tier || ''}
    />
  );
};

export const OrganizationsList = ({ entityId, entities, onChange }: IOrganizationsListProps) => {
  const { classes } = useStyles();
  const [searchInputplaceholder, setSearchInputPlaceholder] = useState(content.SEARCH_PLACEHOLDER_BLURED);
  const { handleOrgSearch, clearSearchFilter, orgSearchValue, filteredOrgs } = useEntityFilter({ entities: entities });

  const showSearchFilter = entities.length > maxAllowedOrganizationsWithoutSearchInput;

  const endAdornment = () => {
    if (orgSearchValue.length > 0) {
      return (
        <InputAdornment position="end">
          <IconButton onClick={clearSearchFilter}>
            <CloseRoundedIcon />
          </IconButton>
        </InputAdornment>
      );
    }
    return null;
  };

  const renderItems = useCallback(
    (index: number, data: IEntity) => {
      return renderRow({ index, data }, entityId ?? '', onChange);
    },
    [entityId, onChange]
  );

  const calculatedHeightOfOrgList = useMemo(() => {
    if (filteredOrgs.length < maxAllowedOrganizationsWithoutSearchInput) {
      return filteredOrgs.length * listItemHeight;
    }

    return heightOfListWithSearchInput;
  }, [filteredOrgs.length]);

  return (
    <div className={classes.container}>
      {showSearchFilter && (
        <TextField
          variant="standard"
          onChange={handleOrgSearch}
          placeholder={searchInputplaceholder}
          value={orgSearchValue}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
            e.stopPropagation();
          }}
          onFocus={() => {
            setSearchInputPlaceholder(content.SEARCH_PLACEHOLDER_FOCUSED);
          }}
          onBlur={() => {
            setSearchInputPlaceholder(content.SEARCH_PLACEHOLDER_BLURED);
          }}
          autoFocus={false}
          className={classes.searchInput}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <IconButton>
                  <SearchRoundedIcon />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: endAdornment(),
          }}
        />
      )}
      <Divider />
      {filteredOrgs.length === 0 ? (
        <EmptyOrganizationItem />
      ) : (
        <Virtuoso
          data={filteredOrgs}
          style={{
            height: `${calculatedHeightOfOrgList}px`,
            width: `${widthOfListWith}px`,
          }}
          totalCount={filteredOrgs.length}
          itemContent={renderItems}
          increaseViewportBy={virtualizedListOffset}
        />
      )}
    </div>
  );
};
