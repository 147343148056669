import { CRITICALITY, TCriticality } from '../models';
import { useStyles } from './dot.styles';

interface IDot {
  variant: TCriticality;
  labelClassName?: string;
  children: JSX.Element;
}

export const Dot = ({ variant, labelClassName, children }: IDot) => {
  const { classes, cx } = useStyles();

  switch (variant) {
    case CRITICALITY.HIGH: {
      return (
        <div className={cx(classes.label, labelClassName)} data-testid="label">
          <div className={`${classes.dot} ${classes.high}`}></div>
          {children}
        </div>
      );
    }
    case CRITICALITY.LOW: {
      return (
        <div className={cx(classes.label, labelClassName)} data-testid="label">
          <div className={`${classes.dot} ${classes.low}`}></div>
          {children}
        </div>
      );
    }
    case CRITICALITY.NO: {
      return (
        <div className={cx(classes.label, labelClassName)} data-testid="label">
          <div className={`${classes.dot} ${classes.no}`}></div>
          {children}
        </div>
      );
    }
    default: {
      return null;
    }
  }
};
