import { createDateString } from '@marlin/shared/utils-common-date';
import { AnalyticsTelemetryResponse, TAnalyticsTelemetryResponse } from '@marlin/shared/utils-format-reading';
import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { paths } from './api';
import {
  BucketedRangeAnalyticsTelemetrySchema,
  TAnalyticsTelemetryRequestParams,
} from './schema/telemetry-analytics.request.schema';
import {
  TRangeTelemetryParams,
  bucketedRangeTelemetrySchema,
  rangeTelemetrySchema,
} from './schema/telemetry.request.schema';
import { TTelemetry, telemetrySchema } from './schema/telemetry.response.schema';

export const getRangeTelemetry = async (
  requestParams: TRangeTelemetryParams,
  calculateBuckets = true
): Promise<TTelemetry> => {
  const params = safeParseData(requestParams, calculateBuckets ? bucketedRangeTelemetrySchema : rangeTelemetrySchema);
  const res = await getHttpClient().get<unknown, TTelemetry>(paths.RANGE_TELEMETRY, {
    params,
  });
  return safeParseData(
    res,
    calculateBuckets
      ? telemetrySchema.transform((data) => {
          return {
            ...data,
            chartData: [
              { eventDateTime: createDateString(params.from), value: null },
              ...(data.chartData || []),
              { eventDateTime: createDateString(params.to), value: null },
            ],
          };
        })
      : telemetrySchema
  );
};

export const getAnalyticsTelemetry = async (
  params: TAnalyticsTelemetryRequestParams
): Promise<TAnalyticsTelemetryResponse> => {
  const paramsWithBuckets = safeParseData(params, BucketedRangeAnalyticsTelemetrySchema);
  const res = await getHttpClient().post<unknown, TAnalyticsTelemetryResponse>(paths.ANALYTICS_TELEMETRY, {
    ...paramsWithBuckets,
  });

  const parsedData = safeParseData(res, AnalyticsTelemetryResponse);

  if (params.chartDisplayType === 'bar') return parsedData;

  return {
    ...parsedData,
    telemetryData: parsedData.telemetryData.map((data) => ({
      ...data,
      values: removeGaps(data.values),
    })),
  };
};

const removeGaps = (arr: Array<{ x: number; y: string | undefined }>): Array<{ x: number; y: string | undefined }> => {
  // Remove null values from the beginning and end of the array, should be done on BE in the future
  // First pass: filter and keep track of indices of non-null values
  const nonNullIndices = arr.reduce<number[]>((acc, item, index) => {
    if (item.y) acc.push(index);
    return acc;
  }, []);

  // Second pass: remove nulls between non-null values if the gap is less than 2 hours
  return arr.filter((item, index) => {
    if (item.y) return true;

    const prevIndex = nonNullIndices.filter((i) => i < index).pop();
    const nextIndex = nonNullIndices.find((i) => i > index);

    if (prevIndex === undefined || nextIndex === undefined) return false;

    const prevTime = arr[prevIndex].x;
    const nextTime = arr[nextIndex].x;

    // Check if the time gap between previous and next non-null values is greater than 2 hours (7200000 milliseconds)
    return nextTime - prevTime > 7200000;
  });
};
