import { TGateway } from '@marlin/asset/data-access/gateway';
import { PowerSource } from '@marlin/asset/shared/ui/battery';
import { ConnectionStatus } from '@marlin/asset/shared/ui/connection-status';
import { CardHeader, DetailsItem } from '@marlin/asset/shared/ui/hub-card';
import { MarlinTheme } from '@marlin/shared/theme';
import { ReadingCard } from '@marlin/shared/ui-asset-card-mobile';
import { ActionEdit, ConfigureAction, ContextMenu } from '@marlin/shared/ui-context-menu';
import { defaultDateTime, formatDate } from '@marlin/shared/utils-common-date';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import RouterRoundedIcon from '@mui/icons-material/RouterRounded';
import { Paper } from '@mui/material';
import { useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';

import { StatusCell } from '../cells';
import { content } from '../content';
import { DeregisterAction } from '../gateway-list/deregister-action.component';
import { useNavigation } from '../hook/use-navigation.hook';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    margin: `${theme.typography.pxToRem(8)} 0`,
    padding: theme.typography.pxToRem(16),
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  status: {
    marginBottom: theme.typography.pxToRem(8),
  },
  readings: {
    display: 'flex',
    flex: '1 1 0',
    gap: theme.typography.pxToRem(8),
  },
  icon: {
    marginRight: theme.typography.pxToRem(8),
    '& > svg': {
      fontSize: theme.typography.pxToRem(20),
    },
  },
}));

export const GatewayCard = ({ gateway, refreshData }: { gateway: TGateway; refreshData: () => void }) => {
  const { classes } = useStyles();
  const { goTo } = useRouter();
  const { getGatewayDetailsPageLink } = useNavigation();

  const configureGateway = useCallback(
    (deviceId: string) => {
      const configureGatewayUrl = routes.gateway.configuration.url(deviceId);
      goTo(configureGatewayUrl);
    },
    [goTo]
  );
  const onEditClick = useCallback(
    (deviceId: string) => {
      const editGatewayUrl = routes.gateway.edit.url(deviceId);
      goTo(editGatewayUrl);
    },
    [goTo]
  );

  return (
    <Paper variant="outlined" className={classes.container}>
      <div className={classes.cardHeader}>
        <CardHeader
          asset={{ name: gateway.gatewayName ?? gateway.gatewayId, id: gateway.id }}
          icon={
            <div className={classes.icon}>
              <RouterRoundedIcon />
            </div>
          }
          goTo={gateway.model !== 'NexaGateway' ? (id: string) => goTo(getGatewayDetailsPageLink(id)) : undefined}
        />
        {gateway.model !== 'NexaGateway' && (
          <ContextMenu>
            <ActionEdit onClick={() => onEditClick(gateway.id)} />
            <ConfigureAction onClick={() => configureGateway(gateway.id)} />
            <DeregisterAction refreshData={refreshData} gateway={gateway} />
          </ContextMenu>
        )}
      </div>
      <div className={classes.status}>
        <StatusCell status={gateway.status} mode={gateway.mode} />
      </div>
      <div className={classes.readings}>
        <ReadingCard title={content.GATEWAY_TABLE_POWER_SOURCE_COLUMN} testId="power-source">
          <PowerSource batteryLevel={gateway.batteryLevel} />
        </ReadingCard>
        <ReadingCard title={content.GATEWAY_TABLE_SIGNAL_STRENGTH_COLUMN} testId="signal-strength">
          <ConnectionStatus
            signalStrength={gateway.signalStrength}
            gatewayModel={gateway.model ?? ''}
            isOnline={gateway.status === 'ACTIVE'}
          />
        </ReadingCard>
      </div>
      <DetailsItem
        testId="gateway-card-last-connection"
        label={content.LAST_CONNECTION}
        value={formatDate(gateway.lastDataReceived, defaultDateTime)}
        tooltip
      />
      <DetailsItem
        testId="gateway-card-active-connections"
        label={content.GATEWAY_TABLE_ACTIVE_SENSORS_COLUMN}
        value={gateway.activeDevices}
      />
      <DetailsItem testId="gateway-card-active-connections" label={content.GATEWAY_ID} value={gateway.gatewayId} />
    </Paper>
  );
};
