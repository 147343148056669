import { getFormattedValue } from '@marlin/shared/utils-format-reading';
import { TDatapointWithMetadata } from '@marlin/shared/utils/datapoint-mappers';
import { TLastReadingValue } from '@marlin/shared/utils/zod';

export const getDatapoint = (
  name: string,
  lastReadingValues?: TLastReadingValue[],
  data?: TDatapointWithMetadata[]
) => {
  const datapoints = data?.length ? data : lastReadingValues;
  const datapoint = datapoints?.find((datapoint) => datapoint.name.toLowerCase() === name.toLowerCase());

  return datapoint ? getFormattedValue(datapoint.value, datapoint.unitOfMeasure) : '';
};
