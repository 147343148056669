import { useMemo, useState } from 'react';

import { IEntity } from './types';

interface IUseEntityFilterProps {
  entities: IEntity[];
}

export const useEntityFilter = ({ entities }: IUseEntityFilterProps) => {
  const [orgSearchValue, setOrgSearchValue] = useState('');

  const handleOrgSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrgSearchValue(event.target.value);
  };

  const clearSearchFilter = () => {
    setOrgSearchValue('');
  };

  const filteredOrgs = useMemo(() => {
    return entities.filter((org) => org.name.toLowerCase().includes(orgSearchValue.toLowerCase()));
  }, [orgSearchValue, entities]);

  return {
    orgSearchValue,
    filteredOrgs,
    handleOrgSearch,
    clearSearchFilter,
  };
};
