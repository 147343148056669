import { MarlinTheme } from '@marlin/shared/theme';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../content';
import { EQUIPMENT_STATUS } from '../../onboarding.schema';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    width: theme.typography.pxToRem(24),
    height: theme.typography.pxToRem(24),
    marginRight: theme.typography.pxToRem(8),
    alignSelf: 'center',
  },
  connecting: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: theme.typography.pxToRem(8),
  },
}));

interface IEquipmentCardStatusProps {
  status: EQUIPMENT_STATUS;
}

export const EquipmentCardStatus = ({ status }: IEquipmentCardStatusProps) => {
  const { classes } = useStyles();

  const elementByStatus = () => {
    switch (status) {
      case EQUIPMENT_STATUS.REFETCH:
      case EQUIPMENT_STATUS.NEW: {
        return <></>;
      }
      case EQUIPMENT_STATUS.CONNECTING: {
        return <div className={classes.connecting}>{content.CONNECTING}</div>;
      }
      case EQUIPMENT_STATUS.OK: {
        return (
          <div className={classes.container}>
            <CheckCircleRoundedIcon color="success" />
          </div>
        );
      }
      case EQUIPMENT_STATUS.ERROR: {
        return (
          <div className={classes.container}>
            <ErrorRoundedIcon color="error" />
          </div>
        );
      }
    }
  };

  return elementByStatus();
};
