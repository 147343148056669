export const content = {
  EDIT: 'Edit',
  NEXA_GATEWAY: 'Nexa Gateway',
  CONNECTION_STATUS: 'Connection Status',
  CONNECTION_TYPE: 'Connection Type',
  ACTIVE: 'Active',
  STANDBY: 'Standby',
  INACTIVE: 'Inactive',
  ONLINE: 'Online',
  OFFLINE: 'Offline',
  CELLULAR: 'Cellular',
  ETHERNET: 'Ethernet',
  CONFIGURE_CONNECTION: 'Configure Connection',
  WIFI: 'Wi-Fi',
  GATEWAY_ID: 'Gateway ID',
  TABLE_TITLE: 'Connected Sensors & Equipment',
  ERROR: 'Something went wrong. Please try again later.',
  GATEWAY_DETAILS: 'Gateway Details',
  EMPTY_DATAPOINT_VALUE: '--',
  FIRMWARE_VERSION: 'Firmware Version',
  COMMISSION_DATE: 'Commission Date',
  CONFIGURATION_ID: 'Current Configuration File ID',
  DESCRIPTION: 'Description',
  MODE: {
    ACTIVE: 'Active Mode',
    STANDBY: 'Standby',
    INACTIVE: 'Inactive',
  },
  SYSTEM: 'System',
  SAVE_SUCCESS_MSG: 'Changes have been saved',
};
