import { content } from '../content';

export const zipCodeMask = '00000-0000';
export const caZipCodeMask = 'a0a 0a0';

export const inputMask: Record<string, string> = {
  'United States': zipCodeMask,
  Canada: caZipCodeMask,
};

export const postalCodeLabels: Record<string, string> = {
  'United States': content.ZIP_CODE,
  Canada: content.POSTAL_CODE,
};
